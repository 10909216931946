/**
 * @generated SignedSource<<b818d780e18a69b083ea24db96cfbeea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stopId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "stopId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_StopPageMeta_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StopPageMeta_stop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_StopPageMeta_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "desc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44fab7f36493f206233761ee0cce4230",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_StopPageMeta_Query",
    "operationKind": "query",
    "text": "query stopRoutes_StopPageMeta_Query(\n  $stopId: String!\n) {\n  stop(id: $stopId) {\n    ...StopPageMeta_stop\n    id\n  }\n}\n\nfragment StopPageMeta_stop on Stop {\n  name\n  code\n  desc\n}\n"
  }
};
})();

node.hash = "65ce9d39623f0c2213e6fa532da69eab";

module.exports = node;
