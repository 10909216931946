import * as React from 'react';
import { Children, useEffect, useState } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import connectToStores from 'fluxible-addons-react/connectToStores';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl/src/types';
import Loading from '../component/Loading';
import { getJson } from './xhrPromise';

function StoreListeningIntlProvider(props, { config }) {
  const [isLoading, setIsLoading] = useState(true);
  const [translations, setTranslations] = useState({});
  const [showKeys, setShowKeys] = useState(false);
  const { locale } = props;

  useEffect(() => {
    getJson(`${config.URL.I18N_URL}/${locale}`)
      .then(result => {
        setTranslations(result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <IntlProvider
      {...props}
      messages={translations}
      key={showKeys ? 'show-keys' : 'show-values'}
    >
      <IntlFormatter
        showKeys={showKeys}
        toggleShowKeys={() => setShowKeys(!showKeys)}
      >
        {props.children}
      </IntlFormatter>
    </IntlProvider>
  );
}

class IntlFormatter extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    showKeys: PropTypes.bool.isRequired,
    toggleShowKeys: PropTypes.func.isRequired,
  };

  static contextTypes = {
    intl: intlShape,
  };

  static childContextTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props, context = {}) {
    super(props, context);
    window.addEventListener('keydown', this.getListener);
  }

  getListener = event => {
    /* istanbul ignore next */
    if (event.ctrlKey && event.key === 'm') {
      this.props.toggleShowKeys();
    }
  };

  getChildContext() {
    return {
      ...this.context,
      intl: {
        ...this.context.intl,
        formatMessage: (descriptor, values) => {
          if (this.props.showKeys) {
            return this.context.intl.formatMessage(
              descriptor ? { id: `KEY: ${descriptor.id}` } : {},
            );
          }

          return this.context.intl.formatMessage(descriptor, values);
        },
      },
    };
  }

  render() {
    return Children.only(this.props.children);
  }
}

StoreListeningIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
};

StoreListeningIntlProvider.contextTypes = {
  config: PropTypes.object.isRequired,
};

export default connectToStores(
  StoreListeningIntlProvider,
  ['PreferencesStore'],
  context => {
    const language = context.getStore('PreferencesStore').getLanguage();

    // eslint-disable-next-line global-require, import/no-dynamic-require
    addLocaleData(require(`react-intl/locale-data/${language}`));

    return { locale: language };
  },
);
