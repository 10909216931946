/**
 * @generated SignedSource<<879fe089389406b113f53208dff9b25f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_BikeParkMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BikePark",
        "kind": "LinkedField",
        "name": "bikePark",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BikeParkMapContainer_bikePark"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_BikeParkMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BikePark",
        "kind": "LinkedField",
        "name": "bikePark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bikeParkId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d411e0101591f4e18ced14d3e34ff83",
    "id": null,
    "metadata": {},
    "name": "routes_BikeParkMap_Query",
    "operationKind": "query",
    "text": "query routes_BikeParkMap_Query(\n  $id: String!\n) {\n  bikePark(id: $id) {\n    ...BikeParkMapContainer_bikePark\n    id\n  }\n}\n\nfragment BikeParkMapContainer_bikePark on BikePark {\n  lat\n  lon\n  name\n  bikeParkId\n}\n"
  }
};
})();

node.hash = "1fe0087d3006cc14398baf9f57559474";

module.exports = node;
