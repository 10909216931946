/**
 * @generated SignedSource<<8295db35607b8f65b0a0af474c75528e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "terminalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "terminalId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "alerts",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alertSeverityLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effectiveEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effectiveStartDate",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_TerminalPageTabContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TerminalPageTabContainer_station"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_TerminalPageTabContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "station",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Stop",
            "kind": "LinkedField",
            "name": "stops",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": "stoptimes",
            "args": [
              {
                "kind": "Literal",
                "name": "numberOfDepartures",
                "value": 100
              },
              {
                "kind": "Literal",
                "name": "omitCanceled",
                "value": false
              },
              {
                "kind": "Literal",
                "name": "timeRange",
                "value": 3600
              }
            ],
            "concreteType": "Stoptime",
            "kind": "LinkedField",
            "name": "stoptimesWithoutPatterns",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realtimeState",
                "storageKey": null
              }
            ],
            "storageKey": "stoptimesWithoutPatterns(numberOfDepartures:100,omitCanceled:false,timeRange:3600)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9ce950bfcc99f9fa3a94cda975c48ab",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_TerminalPageTabContainer_Query",
    "operationKind": "query",
    "text": "query stopRoutes_TerminalPageTabContainer_Query(\n  $terminalId: String!\n) {\n  station(id: $terminalId) {\n    ...TerminalPageTabContainer_station\n    id\n  }\n}\n\nfragment TerminalPageTabContainer_station on Stop {\n  id\n  gtfsId\n  code\n  stops {\n    id\n    gtfsId\n    alerts {\n      id\n      alertSeverityLevel\n      effectiveEndDate\n      effectiveStartDate\n    }\n  }\n  alerts {\n    id\n    alertSeverityLevel\n    effectiveEndDate\n    effectiveStartDate\n  }\n  stoptimes: stoptimesWithoutPatterns(timeRange: 3600, numberOfDepartures: 100, omitCanceled: false) {\n    realtimeState\n  }\n}\n"
  }
};
})();

node.hash = "0b22a10b3d371672275bcdd043687482";

module.exports = node;
