/**
 * @generated SignedSource<<d0e095894749535e043787e067f12560>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "patternId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tripId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "patternId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tripId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directionId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lat",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lon",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "desc",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeRoutes_RoutePageMap_withTrip_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Pattern",
        "kind": "LinkedField",
        "name": "pattern",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutePageMap_pattern"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Trip",
        "kind": "LinkedField",
        "name": "trip",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutePageMap_trip"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeRoutes_RoutePageMap_withTrip_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Pattern",
        "kind": "LinkedField",
        "name": "pattern",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headsign",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "route",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortName",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Coordinates",
            "kind": "LinkedField",
            "name": "geometry",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Stop",
            "kind": "LinkedField",
            "name": "stops",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v3/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zoneId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Alert",
                "kind": "LinkedField",
                "name": "alerts",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertSeverityLevel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveEndDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveStartDate",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stop",
                "kind": "LinkedField",
                "name": "stops",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platformCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "activeDates",
            "args": null,
            "concreteType": "Trip",
            "kind": "LinkedField",
            "name": "trips",
            "plural": true,
            "selections": [
              {
                "alias": "day",
                "args": null,
                "kind": "ScalarField",
                "name": "activeDates",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Trip",
        "kind": "LinkedField",
        "name": "trip",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Stoptime",
            "kind": "LinkedField",
            "name": "stoptimesForDate",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledDeparture",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ac969b64d8f7407710a409f589cdb93",
    "id": null,
    "metadata": {},
    "name": "routeRoutes_RoutePageMap_withTrip_Query",
    "operationKind": "query",
    "text": "query routeRoutes_RoutePageMap_withTrip_Query(\n  $patternId: String!\n  $tripId: String!\n) {\n  pattern(id: $patternId) {\n    ...RoutePageMap_pattern\n    id\n  }\n  trip(id: $tripId) {\n    ...RoutePageMap_trip\n    id\n  }\n}\n\nfragment RouteLine_pattern on Pattern {\n  code\n  geometry {\n    lat\n    lon\n  }\n  route {\n    mode\n    type\n    color\n    id\n  }\n  stops {\n    lat\n    lon\n    name\n    gtfsId\n    platformCode\n    code\n    ...StopCardHeaderContainer_stop\n    id\n  }\n}\n\nfragment RoutePageMap_pattern on Pattern {\n  code\n  directionId\n  headsign\n  route {\n    type\n    mode\n    shortName\n    id\n  }\n  geometry {\n    lat\n    lon\n  }\n  stops {\n    lat\n    lon\n    name\n    gtfsId\n    ...StopCardHeaderContainer_stop\n    id\n  }\n  activeDates: trips {\n    day: activeDates\n    id\n  }\n  ...RouteLine_pattern\n}\n\nfragment RoutePageMap_trip on Trip {\n  stoptimesForDate {\n    scheduledDeparture\n  }\n  gtfsId\n  directionId\n}\n\nfragment StopCardHeaderContainer_stop on Stop {\n  gtfsId\n  name\n  code\n  desc\n  zoneId\n  alerts {\n    alertSeverityLevel\n    effectiveEndDate\n    effectiveStartDate\n    id\n  }\n  lat\n  lon\n  stops {\n    name\n    desc\n    id\n  }\n}\n"
  }
};
})();

node.hash = "e32cf599393d4cef508051d91ab76f6f";

module.exports = node;
