import Store from 'fluxible/addons/BaseStore';
import Cookies from 'universal-cookie';
import moment from 'moment';
import getJson from '@digitransit-search-util/digitransit-search-util-get-json';

const cookies = new Cookies(null, { path: '/' });
const TEN_MINUTES = 10 * 60 * 1000;
const EVERY_MINUTE = 60 * 1000;
let apiUrl;
let parameters = [];

const setCookie = () => {
  const currentTimeInMillis = moment().valueOf();
  cookies.set('pslu', currentTimeInMillis, {
    // parameter store last update
    // Good up to one year
    maxAge: (currentTimeInMillis + TEN_MINUTES * 2) / 1000,
    path: '/',
    Secure: true,
    SameSite: 'Strict',
  });
};

const updateParameters = () => {
  getJson(apiUrl).then(result => {
    if (result) {
      parameters = result;
    }
  });
};

class ParameterStore extends Store {
  static storeName = 'ParameterStore';

  constructor(dispatcher) {
    super(dispatcher);
    const { config } = dispatcher.getContext();
    apiUrl = config.URL.ADMIN_PARAMS_URL;
    //console.log(cookies.get('pslu'));
    if (parameters.length && parameters.length < 1) {
      updateParameters();
    }
    setInterval(this.checkIfNeedUpdate, EVERY_MINUTE);
  }

  checkIfNeedUpdate() {
    if (cookies.get('pslu') !== undefined) {
      const end = cookies.get('pslu');
      const currentTimeInMillis = moment().valueOf();
      if (currentTimeInMillis > end + TEN_MINUTES) {
        updateParameters();
        setCookie();
      } else if (parameters?.length < 1) {
        updateParameters();
      }
    } else if (parameters?.length < 1) {
      updateParameters();
      setCookie();
    } else {
      setCookie();
    }
  }

  getParameters() {
    return parameters;
  }
}

export default ParameterStore;
