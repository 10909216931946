/**
 * @generated SignedSource<<03ec59548936c483f9759b684701c899>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "routeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "routeId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeRoutes_RoutePageMeta_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "route",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoutePageMeta_route"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeRoutes_RoutePageMeta_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "route",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b848325142ddeb3c77289479fb6ee8a3",
    "id": null,
    "metadata": {},
    "name": "routeRoutes_RoutePageMeta_Query",
    "operationKind": "query",
    "text": "query routeRoutes_RoutePageMeta_Query(\n  $routeId: String!\n) {\n  route(id: $routeId) {\n    ...RoutePageMeta_route\n    id\n  }\n}\n\nfragment RoutePageMeta_route on Route {\n  shortName\n  longName\n}\n"
  }
};
})();

node.hash = "d71eb95727efccd4f26a2eb822a816c1";

module.exports = node;
