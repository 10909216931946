/**
 * @generated SignedSource<<a689d5d19bade5acec90d1df59490b7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "routeId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "routeId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tripLongName",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "serviceDate",
    "variableName": "date"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledDeparture",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceDay",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeRoutes_RoutePage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "route",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "date",
                "variableName": "date"
              }
            ],
            "kind": "FragmentSpread",
            "name": "RoutePage_route"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "routeRoutes_RoutePage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Route",
        "kind": "LinkedField",
        "name": "route",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Agency",
            "kind": "LinkedField",
            "name": "agency",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fareUrl",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pattern",
            "kind": "LinkedField",
            "name": "patterns",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "directionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "headsign",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stop",
                "kind": "LinkedField",
                "name": "stops",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "activeDates",
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trips",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceId",
                    "storageKey": null
                  },
                  {
                    "alias": "day",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activeDates",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "patternTrips",
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trips",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tripHeadsign",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TripTimetableType",
                "kind": "LinkedField",
                "name": "patternTimetable",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceValidityType",
                    "kind": "LinkedField",
                    "name": "validity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "validFrom",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "validTill",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "tripsForDate",
                "plural": true,
                "selections": [
                  {
                    "alias": "stoptimes",
                    "args": (v7/*: any*/),
                    "concreteType": "Stoptime",
                    "kind": "LinkedField",
                    "name": "stoptimesForDate",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Alert",
                "kind": "LinkedField",
                "name": "alerts",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertDescriptionText",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertHash",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertHeaderText",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertSeverityLevel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "alertUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveEndDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "effectiveStartDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trip",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "trips",
                "args": (v7/*: any*/),
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "tripsForDate",
                "plural": true,
                "selections": [
                  {
                    "alias": "stoptimes",
                    "args": (v7/*: any*/),
                    "concreteType": "Stoptime",
                    "kind": "LinkedField",
                    "name": "stoptimesForDate",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "realtimeState",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduledArrival",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ba715b6ed5ce3534101247449e607c3",
    "id": null,
    "metadata": {},
    "name": "routeRoutes_RoutePage_Query",
    "operationKind": "query",
    "text": "query routeRoutes_RoutePage_Query(\n  $routeId: String!\n  $date: String!\n) {\n  route(id: $routeId) {\n    ...RoutePage_route_19b1FI\n    id\n  }\n}\n\nfragment RouteAgencyInfo_route on Route {\n  agency {\n    name\n    url\n    fareUrl\n    id\n  }\n}\n\nfragment RoutePage_route_19b1FI on Route {\n  gtfsId\n  color\n  shortName\n  longName\n  mode\n  type\n  ...RouteAgencyInfo_route\n  ...RoutePatternSelect_route_19b1FI\n  agency {\n    name\n    phone\n    id\n  }\n  patterns {\n    alerts {\n      id\n      alertDescriptionText\n      alertHash\n      alertHeaderText\n      alertSeverityLevel\n      alertUrl\n      effectiveEndDate\n      effectiveStartDate\n    }\n    headsign\n    code\n    trip {\n      tripLongName\n      id\n    }\n    trips: tripsForDate(serviceDate: $date) {\n      stoptimes: stoptimesForDate(serviceDate: $date) {\n        realtimeState\n        scheduledArrival\n        scheduledDeparture\n        serviceDay\n      }\n      id\n    }\n    activeDates: trips {\n      serviceId\n      day: activeDates\n      id\n    }\n    id\n  }\n}\n\nfragment RoutePatternSelect_route_19b1FI on Route {\n  shortName\n  mode\n  gtfsId\n  patterns {\n    code\n    directionId\n    headsign\n    stops {\n      name\n      id\n    }\n    activeDates: trips {\n      serviceId\n      day: activeDates\n      id\n    }\n    patternTrips: trips {\n      gtfsId\n      tripHeadsign\n      tripLongName\n      id\n    }\n    patternTimetable {\n      validity {\n        validFrom\n        validTill\n      }\n    }\n    tripsForDate(serviceDate: $date) {\n      stoptimes: stoptimesForDate(serviceDate: $date) {\n        scheduledDeparture\n        serviceDay\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "a1a967db7bd694fe34f1a955119979f5";

module.exports = node;
