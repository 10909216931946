/**
 * @generated SignedSource<<b4f2331a83a9d39005bc4ff7002a0306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CarParkMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CarPark",
        "kind": "LinkedField",
        "name": "carPark",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CarParkMapContainer_carPark"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_CarParkMap_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CarPark",
        "kind": "LinkedField",
        "name": "carPark",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lon",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "carParkId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "054122c617cb63938868f574574b1021",
    "id": null,
    "metadata": {},
    "name": "routes_CarParkMap_Query",
    "operationKind": "query",
    "text": "query routes_CarParkMap_Query(\n  $id: String!\n) {\n  carPark(id: $id) {\n    ...CarParkMapContainer_carPark\n    id\n  }\n}\n\nfragment CarParkMapContainer_carPark on CarPark {\n  lat\n  lon\n  name\n  carParkId\n}\n"
  }
};
})();

node.hash = "d5ed5767c3201f1ad0949ffe51277d32";

module.exports = node;
