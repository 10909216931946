/**
 * @generated SignedSource<<2617e579ccbb9ecf38760bdc5f588ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "stopId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "stopId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_StopPageTab_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StopPageTabContainer_stop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "stopRoutes_StopPageTab_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gtfsId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "alerts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertSeverityLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveEndDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveStartDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "stoptimes",
            "args": [
              {
                "kind": "Literal",
                "name": "numberOfDepartures",
                "value": 100
              },
              {
                "kind": "Literal",
                "name": "omitCanceled",
                "value": false
              },
              {
                "kind": "Literal",
                "name": "timeRange",
                "value": 3600
              }
            ],
            "concreteType": "Stoptime",
            "kind": "LinkedField",
            "name": "stoptimesWithoutPatterns",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realtimeState",
                "storageKey": null
              }
            ],
            "storageKey": "stoptimesWithoutPatterns(numberOfDepartures:100,omitCanceled:false,timeRange:3600)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7cabb05b1912ff400c4437e089cb59cb",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_StopPageTab_Query",
    "operationKind": "query",
    "text": "query stopRoutes_StopPageTab_Query(\n  $stopId: String!\n) {\n  stop(id: $stopId) {\n    ...StopPageTabContainer_stop\n    id\n  }\n}\n\nfragment StopPageTabContainer_stop on Stop {\n  id\n  gtfsId\n  code\n  alerts {\n    id\n    alertSeverityLevel\n    effectiveEndDate\n    effectiveStartDate\n  }\n  stoptimes: stoptimesWithoutPatterns(timeRange: 3600, numberOfDepartures: 100, omitCanceled: false) {\n    realtimeState\n  }\n}\n"
  }
};
})();

node.hash = "b138246795b1f9b890c6b5823e28b3f4";

module.exports = node;
