/* eslint sort-keys: "error" */

const translations = {
  en: {
    'about-service-feedback': 'About the service and feedback',
    'about-these-pages': 'About the web pages',
    'about-this-service': 'About the service',
    access_with: 'access with',
    accessibility: 'Accessibility',
    'accessibility-limited': 'Wheelchair',
    'accessibility-nolimit': 'No limits',
    'accessibility-statement': 'Accessibility statement',
    'add-favourite-bikeStation-failed-heading':
      'Saving the citybike station failed',
    'add-favourite-place-failed-heading': 'Unable to save location',
    'add-favourite-route-failed-heading': 'Saving the route failed',
    'add-favourite-station-failed-heading': 'Saving the station failed.',
    'add-favourite-stop-failed-heading': 'Saving the stop failed',
    'add-home': 'Add home',
    'add-itinerary-via-point': 'Add via point for itinerary',
    'add-location-to-favourites': 'Add an important location to your Favorites',
    'add-to-favourites': 'Add to your Favorites',
    'add-via-button-label': 'Add via point',
    'add-via-duration-button-label': 'Set stop duration at via point {index}',
    'add-via-duration-button-label-close':
      'Close stop duration selector at via point {index}',
    'add-via-duration-button-label-open':
      'Open stop duration selector at via point {index}',
    'add-work': 'Add work',
    all: 'All',
    'alternative-legs':
      'Also {leg1} at {startTime1} and {leg2} at {startTime2}',
    'alternative-legs-single': 'Also {leg1} at {startTime1}',
    'aria-itinerary-summary':
      'Total journey time {duration}. Departing at {inFuture} {departureTime} and arriving at {arrivalTime}',
    'aria-itinerary-summary-bike-distance':
      'Total cycling {duration}, {distance} ',
    'aria-itinerary-summary-car-distance':
      'Total driving {duration}, {distance} ',
    'aria-itinerary-summary-walk-distance':
      'Total walking {duration}, {distance} ',
    'arrive-leave': 'Arrive or leave at selected time',
    'as-destination': 'Destination',
    'as-origin': 'Origin',
    'as-viapoint': 'Viapoint',
    'search-autosuggest-label':
      'Venue, place and stopsearch. Navigate list with arrow keys and select with enter key',
    'search-autosuggest-len':
      'There {len, plural, =1 {is 1 suggestion} other {are {len} suggestions}} available',
    'search-current-suggestion': 'Current selection: {selection}',
    'select-date': 'Select date',
    'select-from-map-destination': 'Select destination',
    'select-from-map-favourite': 'Select from map',
    'select-from-map-no-title': 'No title',
    'select-from-map-origin': 'Select origin',
    'select-from-map-viaPoint': 'Select viapoint',
    'set-time-earlier-button-label': 'Set travel time to earlier',
    'set-time-later-button-label': 'Set travel time to later',
    'set-time-now-button-label': 'Set travel time to current',
    'time-navigation-buttons': 'Time navigation buttons',
    'time-selector-form': 'Edit time',
    // eslint-disable-next-line sort-keys
    address: 'Address',
    'address-or-stop': 'Address or stop',
    agency: 'Operator',
    airplane: 'Flight',
    'airplane-with-route-number': 'Flight {routeNumber}',
    'airport-check-in':
      'Check-in immediately upon arrival if you have not completed check-in in advance',
    'airport-collect-luggage': 'Collect your luggage',
    'airport-security-check-go-to-gate':
      'Proceed to your gate through security check',
    'all-routes': 'All routes',
    'all-routes-disabled': 'No routes',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arriving',
    'arriving-soon': 'Now',
    'at-time': 'at',
    'avoid-transfers': 'Avoid transfers',
    'avoid-walking': 'Avoid walking',
    'back-button-title': 'Go back to previous page',
    'back-to-front-page': 'Back to the front page',
    bicycle: 'Bike',
    'bicycle-distance-duration': 'Bike {duration} ({distance})',
    'bicycle-walk-from-transit':
      'Take your bike off the {transportMode} and walk it for {distance} ({duration})',
    'bicycle-walk-from-transit-no-duration':
      'Walk your bike off the {transportMode}',
    'bicycle-walk-to-transit':
      'Walk your bike for {distance} ({duration}) and take it on the {transportMode}',
    'bicycle-walk-to-transit-no-duration':
      'Walk your bike to the {transportMode}',
    bicycle_frame_lock: 'Frame locking',
    bicycle_walk: 'Walking a bike',
    'bike-availability': 'Bikes available at the station right now',
    'bike-not-allowed-bus': 'Bikes are not allowed on the bus',
    'bike-not-allowed-tram': 'Bikes are not allowed on the tram',
    'bike-only': 'Cycling only',
    'bike-park': 'Park & Ride for bikes',
    'bike-park-disclaimer':
      'You can conveniently combine cycling and public transport: leave your bike at a Park & Ride and continue by train, bus, metro or tram. Bike parking is free of charge and there are no time limits.',
    'bike-park-disclaimer-header': 'Park your bike',
    'bike-park-disclaimer-link': 'Read more about Park & Ride for bikes',
    'bike-station-disabled': 'The bike station is out of service.',
    'bikes-available': '{amount} bikes at the station ',
    'biketransport-citybike': "I'm using a citybike",
    'biketransport-only-bike': "I'm travelling only by bike",
    'biking-amount': 'Amount of biking',
    'biking-speed': 'Biking speed',
    bus: 'Bus',
    'bus-express': 'Trunk bus',
    'bus-local': 'Neighbourhood bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    buy: 'Buy',
    'buy-ticket': 'How to buy a ticket',
    call: 'Call',
    cancel: 'Cancel',
    canceled: 'Canceled',
    'canceled-itineraries-amount':
      'Additional {itineraryAmount, plural, =1 {1 canceled itinerary} other {{itineraryAmount} canceled itineraries}}',
    'canceled-itineraries-amount-hide':
      'Hide canceled itineraries ({itineraryAmount})',
    'canceled-legs': 'Canceled departures on the route',
    car: 'Car',
    'car-distance-duration': 'Drive {duration} ({distance})',
    'car-distance-no-duration': 'Drive {distance}',
    'car-park-disclaimer':
      'You can conveniently combine car and public transport: leave your car at a Park & Ride and continue by train, bus, metro or tram. Car parking is free of charge and there are no time limits.',
    'car-park-disclaimer-header': 'Park your car',
    'car-park-disclaimer-link': 'Read more about Park & Ride',
    car_park: 'Park & ride',
    cause: 'cause',
    'char-left': 'characters',
    'choose-freely': 'Optional',
    'choose-stop': 'Select stop',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'City bike',
    'citybike-distance-duration': 'Bike {duration} ({distance})',
    'citybike-duration-general-header':
      'Extra charge applies to several sections of route to be completed by a city bike.',
    'citybike-duration-info':
      'You can ride a city bike for up to {duration} minutes at a time, after which you will be charged extra.',
    'citybike-duration-info-header':
      'The section of route to be completed by a city bike takes more than {duration} minutes.',
    'citybike-duration-info-short':
      'Section of route takes more than {duration} min. Extra charge applies.',
    'citybike-network-headers': 'Citybikes',
    'citybike-purchase-link': 'Buy',
    'citybike-register-required': 'To use city bikes, you need to register',
    'citybike-return-full': 'Returning a city bike?',
    'citybike-return-full-link':
      'How to return a bike when a bike station is full ›',
    'citybike-start-using': 'Start using city bikes',
    'citybike-start-using-info': 'More information',
    'citybike-station': 'Bike station {stationId}',
    'citybike-station-no-id': 'Bike station',
    'clear-button-label': 'Clear',
    close: 'Close',
    'close-favourite-module': 'Close the Favorites module',
    'close-settings': 'Close window and save settings',
    'contact-information': '{contactName} contact details',
    continue: 'Continue',
    'cookie-settings': 'Cookie settings',
    'copy-code': 'Copy the code',
    covered: 'Sheltered',
    'create-account': 'Create {contactName} account',
    'create-embedded-search': 'Create a route search element',
    'create-stop-monitor': 'Create a stop display',
    'cycle-distance-duration': 'Cycle {duration} ({distance})',
    'cyclewalk-distance-duration': 'Walk your bike {duration} ({distance})',
    // eslint-disable-next-line sort-keys
    currentposition: 'Current position',
    datasources: 'Data sources',
    date: 'Date',
    day: 'Day',
    delete: 'Delete',
    'delete-favourite-failed-heading': 'Unable to delete save',
    'departure-list-update.sr-instructions':
      'The list of upcoming departures and departure times will update in real time.',
    'departure-page-sr': 'Trip {shortName} {destination} {time} information',
    'departure-time-in-minutes': '{minutes} min',
    'departure-time-sr': '{when} clock {time}. {realTime}',
    departures: 'Departures',
    'departures-by-hour': 'Departures by hour',
    'departures-by-hour-minutes-route': '(minutes/route)',
    'departures-not-found':
      'Unfortunately, no timetable was found for the stop you selected',
    'departures-not-found-time-threshold':
      'Unfortunately, no timetable was found for the stop you selected. It is possible that the day you searched for is too ar in the future. Please try again nearer to the time of your journey',
    description: 'Find public transport services to locations near and far.',
    destination: 'Destination',
    'destination-defined': 'Destination defined',
    'destination-outside-service':
      'No route suggestions were found because the destination is outside the service area.',
    'destination-placeholder': 'Enter destination, route or stop',
    'dialog-return-to-map': 'Return to map',
    'digitransit-platform': 'Digitransit service platform',
    disclaimer:
      'Please note that the results are based on estimated travel times. The suggested transport connections cannot be guaranteed.',
    disruption: 'Disruption',
    'disruption-info': 'Disruption info',
    'disruption-info-no-alerts': 'No known disruptions or diversions.',
    'disruption-info-route-no-alerts':
      'No known disruptions or diversions to the route.',
    disruptions: 'Disruptions',
    'disruptions-and-diversions': 'Disruptions and diversions',
    'disruptions-tab.sr-disruptions': 'One or more known disruptions',
    'disruptions-tab.sr-no-disruptions': 'No known disruptions',
    'distance-between': 'Distance {distance1} m — {distance2} m',
    'distance-total': 'Total distance',
    'distance-under': 'Distance less than {distance} m',
    earlier: 'Earlier departures',
    'edit-favourites': 'Edit the location in the Favorites',
    'elevation-gained-total': 'Elevation gained',
    'elevation-lost-total': 'Elevation lost',
    'embedded-search.choose-language': 'Language',
    'embedded-search.choose-mode': 'Means of transport',
    'embedded-search.choose-width-component': 'Width of the embedding',
    'embedded-search.form-heading': 'Embedding tools',
    engine_ignition_aid: 'Engine starting aid',
    english: 'English',
    explanations: 'Explanations',
    'extra-info': 'More info',
    'favourite-failed-text': 'Please try again in a while.',
    'favourite-target': 'Favorite location',
    ferry: 'Ferry',
    'ferry-with-route-number': 'Ferry {routeNumber} {headSign}',
    'fetch-new-route': 'Fetch a new route',
    finnish: 'Finnish',
    'free-of-charge': 'Free',
    'from-ferry': 'ferry',
    'from-rail': 'train',
    'from-station': 'from station',
    'from-stop': 'from stop',
    'from-subway': 'subway',
    frontpage: 'Frontpage',
    funicular: 'Funicular',
    'generic-cancelation': '{mode} {route} {headsign} at {time} is cancelled.',
    'generic-error': 'There was an error',
    'geolocate-yourself': 'Detect your location',
    'geolocation-denied-heading': 'Geolocation denied',
    'geolocation-denied-text':
      'You have not given a permission to use your geolocation. You can allow it from the phone or browser settings.',
    'geolocation-failed-heading': 'Geolocation failed',
    'geolocation-failed-text':
      'Your browser does not support location retrieval.',
    'geolocation-prompt-text':
      'Accept your browser’s request to access your location.',
    'geolocation-timeout-heading':
      'Detecting your location is taking longer than expected.',
    'geolocation-timeout-text':
      'Have you accepted your browser’s request to access your location?',
    'give-destination': 'Enter your destination',
    'give-name-to-location': 'Give the location a descriptive name',
    'give-origin': 'Enter your origin.',
    'give-position': 'Enter your location or origin in the search field.',
    'give-route': 'Search route',
    hour: 'Hour',
    'hour-short': 'h',
    'how-to-rate-service': 'How would you rate the service?',
    hsl_mobile_ticket: 'HSL mobile ticket',
    hsl_ticket: 'HSL ticket',
    hsl_travel_card: 'HSL card',
    'in-addition': 'In addition',
    'include-estonia': 'Include Estonia',
    'index.title': 'Journey Planner',
    inquiry: 'How did you find the new Journey Planner? Please tell us!',
    instructions: 'Instructions',
    'is-open': 'Open:',
    'itinerary-details.biking-leg':
      'At {time} cycle {distance} from {origin} to {to} {destination}. Estimated time {duration}',
    'itinerary-details.car-leg':
      'At {time} drive {distance} from {origin} to {to} {destination}. Estimated time {duration}',
    'itinerary-details.end-leg':
      'At {time} arrive to destination {destination}.',
    'itinerary-details.route-has-info-alert': 'Route has alerts.',
    'itinerary-details.route-has-severe-alert': 'Route has disruptions.',
    'itinerary-details.route-has-unknown-alert': 'Route has disruptions.',
    'itinerary-details.route-has-warning-alert': 'Route has disruptions.',
    'itinerary-details.scooter-leg':
      'At {time} ride your kick scooter {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-details.transit-leg-part-1': 'At {time} {realtime} take',
    'itinerary-details.transit-leg-part-2':
      'from stop {startStop} {startZoneInfo} {trackInfo} to stop {endStop} {endZoneInfo}. Estimated duration {duration}',
    'itinerary-details.via-leg':
      '{arrivalTime} arrive to via point {viaPoint}. {leaveAction}',
    'itinerary-details.walk-leg':
      'At {time} walk {distance} from {origin} to {to} {destination}. Estimated time {duration}',
    'itinerary-feedback-button': 'Send feedback',
    'itinerary-feedback-message': 'Couldn’t find what you were looking for?',
    'itinerary-feedback-placeholder': 'Description (optional)',
    'itinerary-hide-alternative-legs': 'Hide alternatives',
    'itinerary-hide-stops': 'Hide stops',
    'itinerary-in-the-past':
      'The route search falls within a period that is in the past.',
    'itinerary-in-the-past-link': 'Depart now ›',
    'itinerary-in-the-past-title': 'The route options cannot be displayed',
    'itinerary-page.description': 'Itinerary',
    'itinerary-page.hide-details': 'Hide itinerary details',
    'itinerary-page.itineraries-loaded': 'More search results downloaded',
    'itinerary-page.itineraries-updated': 'Search results updated',
    'itinerary-page.loading-itineraries': 'Downloading more search results',
    'itinerary-page.show-details': 'Show itinerary details',
    'itinerary-page.show-details-label': 'Show itinerary {number} details',
    'itinerary-page.title': 'Itinerary',
    'itinerary-page.update-alert': 'Search results updated',
    'itinerary-summary-page-street-mode.update-alert':
      'Walking and biking results updated',
    'itinerary-summary-page.description': 'Route suggestions',
    'itinerary-summary-page.title': 'Route suggestions',
    'itinerary-summary-row.biking-distance':
      'Total biking distance {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Show on map',
    'itinerary-summary-row.description':
      'Itinerary departing at {departureDate} {departureTime} and arriving at {arrivalDate} {arrivalTime}. {firstDeparture} {transfers} Total time {totalTime}.',
    'itinerary-summary-row.first-departure':
      '{vehicle} leaves at {departureTime} from stop {stopName}.',
    'itinerary-summary-row.first-leg-start-time':
      'Leaves at {firstDepartureTime} {firstDepartureStopType} {firstDepartureStop}{firstDeparturePlatform}',
    'itinerary-summary-row.first-leg-start-time-citybike':
      'Departure at {firstDepartureTime} from {firstDepartureStop} bike station',
    'itinerary-summary-row.no-transit-legs': 'Leave when it suits you',
    'itinerary-summary-row.transfers':
      'Transfer to {vehicle} on stop {stopName}',
    'itinerary-summary-row.walking-distance':
      'Total walking distance {totalDistance}.',
    'itinerary-summary.bikeAndPublic-fallback-title':
      'Take your bike with you onboard',
    'itinerary-summary.bikeAndPublic-rail-subway-title':
      'Take your bike with you on the train or to metro',
    'itinerary-summary.bikeAndPublic-rail-title':
      'Take your bike with you on the train',
    'itinerary-summary.bikeAndPublic-subway-title': 'Take your bike to metro',
    'itinerary-summary.bikePark-title': 'Leave your bike at a Park & Ride',
    'itinerary-summary.interline-wait':
      'The route number ({shortName}) and destination ({destination}) will change at the {stop} stop. Waiting time at the stop is {time}. Please wait onboard.',
    'itinerary-summary.interline-wait-multiple-legs':
      'The route number ({shortName}) and destination will change several times during the journey. Total waiting time is {time}. Please do not leave the vehicle.',
    'itinerary-summary.show-on-map': 'Show on map {target}',
    'itinerary-ticket.title': 'Required ticket',
    'itinerary-tickets.title': 'Required tickets',
    'itinerary-time.title': 'Duration',
    'itinerary-walk.title': 'Total walking distance',
    'journeyplanner.title': 'HSL Journey Planner',
    'language-selection': 'Language: {language}',
    later: 'Later departures',
    'leave-your-car-park-and-ride': 'Leave your car at Park & Ride site',
    leaves: 'Leaves',
    'leaving-at': 'Leaving at',
    lighting: 'Lighting',
    'likely-to-recommend':
      'How likely are you to recommend our service to your friends or colleagues?',
    'likely-to-use':
      'How likely are you to use this service rather than the current Journey Planner?',
    loading: 'Loading...',
    localadmin: 'Locality',
    locate: 'Detect location',
    'location-examples': 'e.g. Home, Work, School,...',
    'location-from-map': 'Selected location',
    'location-from-map-confirm': 'Confirm selection',
    'location-home': 'Home',
    'location-school': 'School',
    'location-shopping': 'Shopping',
    'location-sport': 'Sports',
    'location-work': 'Work',
    login: 'Log in',
    'login-content':
      'To save and utilize places, routes and stops, you must log in using your HSL account.',
    'login-header': 'Log in First',
    logout: 'Log out',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Close the main menu',
    'main-menu-label-open': 'Open the main menu',
    'main-mode': "I'm traveling by",
    map: 'Map',
    'map-layer-citybike': 'Citybike stations',
    'map-layer-park-and-ride': 'Park & ride sites',
    'map-layer-stop-bus': 'Bus stops',
    'map-layer-stop-ferry': 'Ferries',
    'map-layer-stop-funicular': 'Funiculars',
    'map-layer-stop-tram': 'Tram stops',
    'map-layer-terminal-bus': 'Bus terminals',
    'map-layer-terminal-rail': 'Train stations',
    'map-layer-terminal-subway': 'Metro stations',
    'map-layer-vehicles': 'Vehicles',
    'menu-faq': 'FAQ',
    'menu-feedback': 'Submit feedback',
    'menu-link-to-privacy-policy': 'Privacy policy',
    'messagebar-label-close-message-bar': 'Close banner',
    'messagebar-label-page': 'Page',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Route',
    'mobile-ticket-purchase-aria':
      'Mobile ticket purchase information. Buy {ticketName} for {price}. Buy ticket from HSL app or download the app',
    'modes.to-bike': 'city bike station',
    'modes.to-bike-park': 'bike park',
    'modes.to-bus': 'bus stop',
    'modes.to-car-park': 'car park',
    'modes.to-place': 'destination',
    'modes.to-rail': 'train station',
    'modes.to-stop': 'stop',
    'modes.to-subway': 'subway station',
    'modes.to-tram': 'tram stop',
    'more-departures': 'More departures',
    'more-settings': 'More settings',
    'move-on-map': 'Move on the map',
    'move-to-tab': 'Go to tab {number}',
    navigate: 'Navigate',
    nearest: '{ mode } near you',
    'nearest-favorites': 'Stops nearby me',
    'nearest-favorites-browse-stops': 'Browse and select stops',
    'nearest-favorites-no-favorites':
      'Timetables for the selected stops and vehicles on map',
    'nearest-no-stops':
      'There are no stops or stations for the selected mode of transport nearby.',
    'nearest-stops': 'The closest stops',
    'nearest-stops-airplane': 'airports',
    'nearest-stops-bus': 'bus stops',
    'nearest-stops-citybike': 'bike stations',
    'nearest-stops-favorite': 'stops nearby me',
    'nearest-stops-ferry': 'ferry piers',
    'nearest-stops-rail': 'train stations',
    'nearest-stops-subway': 'metro stations',
    'nearest-stops-tram': 'tram stops',
    'nearest-stops-update-location': 'Update {mode}',
    'nearest-stops-updated-location': 'Location on the map has changed',
    'network-error': 'Network error',
    // eslint-disable-next-line sort-keys
    neighbourhood: 'Neighbourhood ',
    next: 'Next',
    'no-bike-allowed-popup-train':
      'Bicycles are allowed on commuter trains but on long distance trains you have to pay an extra fee and it might require a reservation.',
    'no-bike-allowed-popup-tram-bus':
      'Bicycles are not allowed on buses or trams. If you are using these vehicles on your route, leave the bicycle at the stop or at a Park-and-Ride. ',
    'no-departures': 'No departures',
    'no-position': 'No location',
    'no-position-no-stops':
      'Your location is not available. We are unable to show the closest stops.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end': 'Please select destination',
    'no-route-end-date-not-in-range':
      'The timetable does not include later departures.',
    'no-route-found':
      'Unfortunately, route {shortName} ({longName}) could not be found.',
    'no-route-msg': 'Unfortunately, no route suggestions were found',
    'no-route-msg-time-threshold':
      'Unfortunately, no route suggestions were found. It is possible that no timetables can yet be found for the day you selected. Please try again nearer to the time of your journey',
    'no-route-msg-with-changes':
      'Unfortunately, no route suggestions were found. Please check your search settings or try changing the origin or destination.',
    'no-route-origin-near-destination':
      'No route suggestions were found because the origin and destination are very close to each other.',
    'no-route-origin-same-as-destination':
      'No route suggestions were found because the origin and destination are the same.',
    'no-route-showing-alternative-options':
      'No route suggestions were found with the your settings. However, we found the following route options:',
    'no-route-start': 'Please select origin',
    'no-route-start-date-too-early': 'There are no earlier departures.',
    'no-route-start-end': 'Please select origin and destination',
    'no-transfers-margin': '1 min',
    'no-trips-found':
      'No journeys found for the selected date ({selectedDate}).',
    'notification-minimize': 'Close message',
    'notification-open': 'Open message',
    now: 'Now',
    'number-of-intermediate-stops':
      '{number, plural, =0 {No stops} one {1 stop} other {{number} stops} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minutes} one {1 minute} other {{number} minutes}}',
    'number-of-spaces': 'Number of spaces:',
    'off-canvas-info':
      'Log in to the service to save your favorites and utilize them on other devices',
    'one-way-journey': 'The length of a one-way journey',
    'open-app': 'Open the app',
    'option-default': 'Average',
    'option-least': 'Slow',
    'option-less': 'Calm',
    'option-more': 'Prompt',
    'option-most': 'Fast',
    or: 'or',
    origin: 'Origin',
    'origin-and-destination': 'Origin and destination',
    'origin-defined': 'Origin defined',
    'origin-outside-service':
      'No route suggestions were found because the origin is outside the service area.',
    'origin-placeholder': 'From? - address or stop',
    'other-dates': 'Other dates',
    'own-position': 'Your current location',
    'page-not-found': 'The page cannot be found.',
    paid: 'Paid',
    'park-and-ride': 'Park and Ride',
    'park-and-ride-availability': 'Spaces available:',
    'pay-attention': 'N.B.',
    payment_at_gate: 'Payment at the gate ',
    'pick-icon': 'Select icon',
    'pick-mode': 'Transport modes',
    'pick-mode-airplane-disabled': 'Flight not selected',
    'pick-mode-airplane-enabled': 'Flight selected',
    'pick-mode-bus-disabled': 'Bus not selected',
    'pick-mode-bus-enabled': 'Bus selected',
    'pick-mode-citybike-disabled': 'Citybike not selected',
    'pick-mode-citybike-enabled': 'Citybike selected',
    'pick-mode-ferry-disabled': 'Ferry not selected',
    'pick-mode-ferry-enabled': 'Ferry selected',
    'pick-mode-rail-disabled': 'Rail not selected',
    'pick-mode-rail-enabled': 'Rail selected',
    'pick-mode-selected': 'Selected transport modes',
    'pick-mode-subway-disabled': 'Metro not selected',
    'pick-mode-subway-enabled': 'Metro selected',
    'pick-mode-tram-disabled': 'Tram not selected',
    'pick-mode-tram-enabled': 'Tram selected',
    'pick-street-mode': 'My modes of transport',
    'place-route-or-keyword': 'Destination, route or stop',
    platform: 'Platform',
    'platform-num': 'Platform {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'platform-short-no-num': 'Plat.',
    position: 'Position',
    'prefer-walking': 'Prefer walking',
    preview: 'Preview',
    previous: 'Previous',
    print: 'Print',
    'print-timetable': 'Weekly timetable',
    'privacy-statement': 'Privacy statement',
    'public-transport': 'Public transport',
    rail: 'Commuter train',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    'read-more': 'Read more',
    realtime: 'Real-time',
    'realtime-matching': 'Real-time integration',
    releases: 'Releases',
    'remove-favourite': 'Remove from favorites',
    'remove-via-button-label': 'Remove via point {index}',
    'rent-cycle-at': 'Fetch a city bike:',
    'rent-scooter-at': 'Rent a kick scooter at {station} station',
    'required-ticket': 'Ticket required for the journey',
    'return-cycle-to': 'Return the bike:',
    'return-scooter-to': 'Return the kick scooter to {station} station',
    'right-now': 'Right now',
    route: 'Route',
    'route-add-viapoint': 'Via point',
    'route-custom-settings': 'Custom settings',
    'route-default': 'Default settings',
    'route-destination-arrives': 'Drop-off only',
    'route-destination-endpoint': 'Arrives / Terminus',
    'route-fastest': 'Fast route',
    'route-from-here': 'Origin',
    'route-guide': 'Route guide',
    'route-here': 'Destination',
    'route-least-elevation-changes': 'Avoid changes in elevation',
    'route-least-transfers': 'Avoid transfers',
    'route-least-walking': 'Avoid walking',
    'route-no-stop': 'No stop',
    'route-not-possible':
      'Unfortunately, your route is not available. Technical error: "{error}"',
    'route-page-vehicle-position':
      '{mode} {shortName} is at the {stopName} stop',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between the {stopName} stop and the {nextStopName} stop.',
    'route-page-vehicle-selected': 'Current selection.',
    'route-page.description': 'Route {shortName} - {longName}',
    'route-page.future-routes': 'Future routes',
    'route-page.pattern-chosen': 'Selected',
    'route-page.pattern-select-title': 'Select the route to be viewed',
    'route-page.similar-routes': 'You might also be interested in',
    'route-page.special-routes': 'Other routes',
    'route-page.title': 'Route {shortName}',
    'route-page.title-short': 'Route',
    'route-to-ferry': 'Route to the pier',
    'route-to-park': 'Route to the park',
    'route-to-station': 'Route to the station',
    'route-to-stop': 'Route to the stop',
    'router-change-departure-time': 'Change departure time ›',
    'router-location-not-found':
      'Please select location on the road network or at a stop.',
    'router-location-not-found-title-1':
      'There is no defined access to the destination',
    'router-location-not-found-title-2':
      'There is no defined access to the origin',
    'router-location-not-found-title-3':
      'There is no defined access to the origin and the destination',
    'router-only-walk':
      'No faster alternatives than walking are available for the route you searched for.',
    'router-only-walk-title': 'Walk the route',
    'router-outside-bounds-3':
      'No route suggestions were found because the origin and destination are outside the service area.',
    'router-outside-service-period':
      'Timetable information is not available for the time you selected.',
    'router-stops-in-range-1':
      'There are no public transport stops close enough to the selected destination.',
    'router-stops-in-range-2':
      'There are no public transport stops close enough to the selected origin.',
    'router-stops-in-range-3':
      'There are no public transport stops close enough to the selected origin and destination.',
    'router-system-error':
      'An error occurred in the system. Please try again later.',
    'router-transit-connection':
      'Please try changing the origin or destination.',
    'router-transit-connection-in-search-window':
      'There are no services available at the selected time.',
    'router-unable': 'Unable to show route options',
    routes: 'Routes',
    'routes-platforms': 'Routes and platforms',
    'routes-tracks': 'Routes and tracks',
    'routing-feedback-header': 'Are you satisfied with the route suggestions?',
    'routing-feedback-link': 'Give feedback',
    run: 'Run',
    save: 'Save',
    'scooter-availability': 'Kick scooters available at the station right now',
    'scooter-distance-duration':
      'Ride your kick scooter {duration} ({distance})',
    'scooter-register-required': 'To use kick scooters, you need to register',
    'scooterwalk-distance-duration':
      'Walk your kick scooter {duration} ({distance})',
    search: 'Search',
    'search-button-label': 'Search',
    'search-destination': 'Search destination, route or stop',
    'search-destination-index': 'Enter destination',
    'search-destination-results-but-no-search':
      'View results in the adjacent “Destination” tab',
    'search-fields.sr-instructions':
      'Route search will take place automatically when you enter origin and destination. Changing search parameters will trigger a new search.',
    'search-no-results': 'No location',
    'search-origin': 'Search origin, route or stop',
    'search-origin-index': 'Enter origin',
    'search-position': 'Detect location',
    'search-search-results-but-no-destination':
      'View results in the adjacent “About the route or stop” tab',
    'searching-position': 'Detecting location...',
    'select-all-routes': 'Select all routes',
    'select-map-layers-header': 'Show on map',
    'select-position': 'Select your location from your previous searches',
    'select-route': 'Select {mode} route {shortName} to {headsign}',
    'selected-routes': 'Selected lines',
    send: 'Send',
    'separate-ticket-required':
      'A separate ticket is required for this part of the itinerary.',
    'separate-ticket-required-disclaimer':
      'This itinerary contains one or more legs for which {agencyName} does not sell tickets.',
    'set-specific-settings': 'Set more specific settings',
    'set-stop-as-routes': "Set the stop as route's",
    settings: 'Settings',
    'settings-alternative-name-rail': 'Train',
    'settings-change-itinerary-settings': 'Change settings',
    'settings-dropdown-close-label': 'Close options',
    'settings-dropdown-open-label': 'Open options',
    'settings-label-change': 'Change settings',
    'settings-missing-itineraries-body':
      'Some selections in the settings exclude certain route alternatives.',
    'settings-missing-itineraries-header':
      'Are some route alternatives missing?',
    share: 'Share itinerary',
    show: 'Show',
    'show-departures': 'Show departures',
    'show-more': 'Show more',
    'show-more-stops-near-you': 'Show more stops near you',
    'show-routes': 'Choose routes',
    'skip-positioning': 'Skip',
    'skip-to-content': 'Skip to content',
    slow: 'Slow',
    'specify-location': 'Specify location',
    'splash-locating': 'Detecting location',
    'splash-or-choose': 'or select your origin',
    'splash-use-positioning': 'Use location services',
    'splash-welcome': 'How do you wish to start?',
    'splash-you-can-also': 'or',
    station: 'Station',
    stop: 'Stop',
    'stop-departure-time-future': 'Departure time is in {minutes} minutes',
    'stop-departure-time-past': 'Departure time was',
    'stop-list-update.sr-instructions':
      'Departure times for each stop will update in real time.',
    'stop-near-you': 'Search stops and routes near you',
    'stop-near-you-modal-grant-permission': 'or enable location services',
    'stop-near-you-modal-grant-permission-info':
      'You can enable location services in your phone or browser settings, allowing the service to use your current location.',
    'stop-near-you-modal-header': 'You have not enabled location services',
    'stop-near-you-modal-info':
      'Using the nearby stops function requires location access.',
    'stop-near-you-title': 'Search for stops and routes',
    'stop-near-you-update-alert': 'Nearest stops updated',
    'stop-number': 'Stop number',
    'stop-page.description': 'Stop {name} - {code}',
    'stop-page.right-now.loaded': 'stop page loaded',
    'stop-page.title': 'Stop {name} - {code}',
    'stop-page.title-short': 'Stop',
    'stop-virtual-monitor': 'Stop display',
    stops: 'Stops',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    'street-mode-bicycle': 'Bicycle',
    'street-mode-bike-aria':
      'Show cycle route. Duration {duration}, distance {length}',
    'street-mode-bikeandvehicle-aria':
      'Show a combination of cycling and public transport. Duration {duration}, distance {length}',
    'street-mode-car': 'Car',
    'street-mode-car-aria':
      'Show driving route. Duration {duration}, distance {length}',
    'street-mode-car_park': 'Park & ride',
    'street-mode-parkandride-aria':
      'Show park and ride route. Duration {duration}, distance {length}',
    'street-mode-public_transport': 'Public transport',
    'street-mode-walk': 'Walking',
    'street-mode-walk-aria':
      'Show walking route. Duration {duration}, distance {length}',
    // eslint-disable-next-line sort-keys
    street: 'Street',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Itinerary suggestion {number}',
    'summary-page.title': 'Itinerary suggestions',
    surveillance_cameras: 'Camera surveillance',
    'swap-order-button-label': 'Reverse origin and destination',
    swedish: 'Swedish',
    'swipe-disruption-info': 'Disruption info  swipe result tabs',
    'swipe-disruption-info-header': 'Disruption info swipe result tabs.',
    'swipe-message-bar': 'Message swipe result tabs',
    'swipe-message-bar-header': 'Message swipe result tabs.',
    'swipe-result-tab-left': 'Press Enter or Space to show the previous tab.',
    'swipe-result-tab-right': 'Press Enter or Space to show the next tab.',
    'swipe-result-tabs': 'Switch tabs using arrow keys.',
    'swipe-sr-new-tab-opened': 'Tab {number} opened.',
    'swipe-stops-near-you': 'Stops near you swipe result tabs.',
    'swipe-stops-near-you-header': 'Stops near you swipe result tabs',
    'swipe-summary-page': 'Itinerary swipe result tabs',
    'swipe-summary-page-header': 'Itinerary swipe result tabs.',
    'terminal-page.description': 'Terminal {name}',
    'terminal-page.title': 'Terminal {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Terms of Use',
    'ticket-and-price-info': 'Ticket and price information',
    'ticket-single-adult': 'Adult',
    'ticket-type-none': 'No limits',
    tickets: 'Tickets',
    time: 'Time',
    'time-selector-hours-label': 'Hour',
    'time-selector-minutes-label': 'Minute',
    timetable: 'Timetable',
    'timetable-summary': 'Timetable Summary',
    'to-frontpage': 'To the front page',
    'to-rail': 'train',
    'to-subway': 'subway',
    today: 'Today',
    tomorrow: 'Tomorrow',
    track: 'Track',
    'track-num': 'Track {platformCode}',
    'track-short': 'Track {platformCode}',
    'track-short-no-num': 'Track',
    tram: 'Tram',
    'tram-with-route-number': 'Tram {routeNumber} {headSign}',
    transfer: 'Transfer',
    transfers: 'Number of transfers',
    'transfers-allowed': 'Transfers allowed',
    'transfers-margin': 'Transfer margin at least',
    'travel-time': '{min} min',
    'travel-time-label': 'Travel time',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'CO2 emissions of the journey',
    'trip-information': 'Trip information',
    'trip-page.description': 'Route {route_short_name} - {route_long_name}',
    'trip-page.title': 'Route {route_short_name}',
    'try-again': 'Try again',
    'use-citybike': 'Start using',
    'use-national-service-postfix': '.',
    'use-national-service-prefix':
      'We recommend you try the national journey planner, ',
    'use-own-position': 'Use current location',
    userinfo: 'My information',
    usermenu: 'User menu',
    'using-modes': 'I want to travel by',
    'very-likely': 'Very likely',
    'very-unlikely': 'Very unlikely',
    'via-leg-stop-duration': 'At via point {stayDuration}',
    'via-point': 'Via point',
    'via-point-index': 'Via point {index}',
    'viapoint-slack-amount': 'Stop duration',
    'view-route': 'View Route',
    // eslint-disable-next-line sort-keys
    venue: 'Venue',
    vr_ticket: 'VR ticket',
    wait: 'Wait',
    'wait-amount-of-time': 'Wait {duration}',
    walk: 'walking',
    'walk-bike-itinerary-1': 'Your search returned only walking routes.',
    'walk-bike-itinerary-2': 'Your search returned only cycling routes.',
    'walk-bike-itinerary-3':
      'Your search returned only walking and cycling routes.',
    'walk-bike-itinerary-4':
      'Your search only returned routes requiring cycling or driving.',
    'walk-distance-duration': 'Walk {duration} ({distance})',
    'walk-only': 'Walking only',
    walking: 'Amount of walking',
    'walking-speed': 'Walking speed',
    'warning-call-agency':
      'Only on demand: {routeName}, which needs to be booked in advance.',
    'warning-call-agency-no-route':
      'Only on demand. Needs to be booked in advance.',
    weather: 'Weather',
    'weather-at-destination': 'Weather at the destination',
    'weather-data-source': 'Source: Finnish Meteorological Institute',
    'weather-detail-title': 'Weather forecast for the place of origin at',
    'weather-icon-1': 'Clear',
    'weather-icon-2': 'Partly cloudy',
    'weather-icon-21': 'Light showers',
    'weather-icon-22': 'Showers',
    'weather-icon-23': 'Heavy showers',
    'weather-icon-3': 'Overcast',
    'weather-icon-31': 'Light rain',
    'weather-icon-32': 'Rain',
    'weather-icon-33': 'Heavy rain',
    'weather-icon-41': 'Light snow showers',
    'weather-icon-42': 'Snow showers',
    'weather-icon-43': 'Heavy snow showers',
    'weather-icon-51': 'Light snowfall',
    'weather-icon-52': 'Snowfall',
    'weather-icon-53': 'Heavy snowfall',
    'weather-icon-61': 'Thundershowers',
    'weather-icon-62': 'Heavy thundershowers',
    'weather-icon-63': 'Thunder',
    'weather-icon-64': 'Heavy thunder',
    'weather-icon-71': 'Light sleet showers',
    'weather-icon-72': 'Sleet showers',
    'weather-icon-73': 'Heavy sleet showers',
    'weather-icon-81': 'Light sleet',
    'weather-icon-82': 'Sleet',
    'weather-icon-83': 'Heavy sleet',
    'weather-icon-91': 'Mist',
    'weather-icon-92': 'Fog',
    where: 'Where to?',
    yesterday: 'Yesterday',
    zone: 'Zone',
    'zone-info': 'Zone {zone}',
    'zone-unknown': 'Unknown zone',
    zones: 'Fare zones',
  },

  fi: {
    'about-service-feedback': 'Tietoja palvelusta ja palaute',
    'about-these-pages': 'Tietoja sivustosta',
    'about-this-service': 'Tietoja palvelusta',
    access_with: 'pääsy',
    accessibility: 'Esteettömyys',
    'accessibility-limited': 'Pyörätuoli',
    'accessibility-nolimit': 'Ei rajoitusta',
    'accessibility-statement': 'Saavutettavuusseloste',
    'add-favourite-bikeStation-failed-heading':
      'Kaupunkipyöräaseman tallentaminen ei onnistunut',
    'add-favourite-place-failed-heading': 'Paikan tallentaminen ei onnistunut',
    'add-favourite-route-failed-heading': 'Linjan tallentaminen ei onnistunut',
    'add-favourite-station-failed-heading':
      'Aseman tallentaminen ei onnistunut',
    'add-favourite-stop-failed-heading': 'Pysäkin tallentaminen ei onnistunut',
    'add-home': 'Lisää koti',
    'add-itinerary-via-point': 'Lisää reitille välipiste',
    'add-location-to-favourites':
      'Lisää sinulle tärkeä paikka Suosikkisi-välilehdelle',
    'add-to-favourites': 'Lisää suosikiksi',
    'add-via-button-label': 'Lisää välipiste',
    'add-work': 'Lisää työ',
    all: 'Kaikki',
    'alternative-legs':
      'Myös {leg1} klo {startTime1} ja {leg2} klo {startTime2}',
    'alternative-legs-single': 'Myös {leg1} klo {startTime1}',
    'aria-itinerary-summary':
      'Matkan kokonaiskesto {duration}. Lähtö {inFuture} kello {departureTime}. Perillä kello {arrivalTime}.',
    'aria-itinerary-summary-bike-distance':
      'pyöräilyä yhteensä {duration}, {distance} ',
    'aria-itinerary-summary-car-distance':
      'ajamista yhteensä {duration}, {distance} ',
    'aria-itinerary-summary-walk-distance':
      'kävelyä yhteensä {duration}, {distance} ',
    'arrive-leave': 'Saavu tai lähde valittuna aikana',
    'as-destination': 'Määränpääksi',
    'as-origin': 'Lähtöpaikaksi',
    'as-viapoint': 'Välipisteeksi',
    'search-autosuggest-label':
      'Paikka, linja ja pysäkkihaku. Navigoi listassa nuolinäppäimillä ja valitse enterillä',
    'search-autosuggest-len':
      ' Löydettiin {len, plural, =1 {1 ehdotus} other {{len} ehdotusta}}',
    'search-current-suggestion': 'Tämänhetkinen valinta: {selection}',
    // eslint-disable-next-line sort-keys
    'add-via-duration-button-label':
      'Aseta välipisteelle {index} pysähdyksen kesto',
    'add-via-duration-button-label-close':
      'Avaa välipisteen {index} pysähdyksen kesto valitsin',
    'add-via-duration-button-label-open':
      'Sulje välipisteen {index} pysähdyksen kesto valitsin',
    'select-date': 'Valitse päivä',
    'select-from-map-destination': 'Valitse määränpää',
    'select-from-map-favourite': 'Valitse kartalta',
    'select-from-map-no-title': 'Ei otsikkoa',
    'select-from-map-origin': 'Valitse lähtöpaikka',
    'select-from-map-viaPoint': 'Valitse välipiste',
    'set-time-earlier-button-label': 'Aikaista matkustusajankohtaa',
    'set-time-later-button-label': 'Myöhäistä matkustusajankohtaa',
    'set-time-now-button-label': 'Aseta matkustusajankohdaksi nyt',
    'time-navigation-buttons': 'Matkustusajankohdan muokkausnapit',
    'time-selector-form': 'Muokkaa Matkustusajankohtaa',
    // eslint-disable-next-line sort-keys
    address: 'Osoite',
    'address-or-stop': 'Osoite tai pysäkki',
    agency: 'Liikennöitsijä',
    airplane: 'Lentokone',
    'airplane-with-route-number': 'Lento {routeNumber}',
    'airport-check-in':
      'Tee lähtöselvitys heti saavuttuasi, jos et ole tehnyt sitä jo etukäteen',
    'airport-collect-luggage': 'Nouda matkatavarat',
    'airport-security-check-go-to-gate':
      'Kävele turvatarkastuksen läpi lähtöportille',
    'all-routes': 'Kaikki linjat',
    'all-routes-disabled': 'Ei linjoja',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Perillä',
    'arriving-soon': 'Nyt',
    'at-time': 'klo',
    'avoid-transfers': 'Vältä vaihtoja',
    'avoid-walking': 'Vältä kävelyä',
    'back-button-title': 'Siirry takaisin edelliselle sivulle',
    'back-to-front-page': 'Siirry etusivulle ›',
    bicycle: 'Polkupyörä',
    'bicycle-distance-duration': 'Pyöräile {duration} ({distance})',
    'bicycle-walk-from-transit':
      'Ota pyörä {transportMode} ja taluta {distance} ({duration})',
    'bicycle-walk-from-transit-no-duration': 'Taluta pyörä {transportMode}',
    'bicycle-walk-to-transit':
      'Taluta {distance} ({duration}) ja ota pyörä mukaan {transportMode}',
    'bicycle-walk-to-transit-no-duration': 'Taluta pyörä {transportMode}',
    bicycle_frame_lock: 'Runkolukitus',
    bicycle_walk: 'Polkupyörän talutus',
    'bike-availability': 'Pyöriä asemalla juuri nyt',
    'bike-not-allowed-bus': 'Pyörää ei voi kuljettaa bussissa',
    'bike-not-allowed-tram': 'Pyörää ei voi kuljettaa raitiovaunussa',
    'bike-only': 'Vain pyöräily',
    'bike-park': 'Liityntäpyöräparkki',
    'bike-park-disclaimer':
      'Voit yhdistää kätevästi joukkoliikenteen ja pyöräilyn, kun jätät pyöräsi liityntäpysäköintiin ja jatkat matkaasi junalla, bussilla, metrolla tai ratikalla. Pyörän pysäköinti on maksutonta eikä siinä ole aikarajaa.',
    'bike-park-disclaimer-header': 'Jätä pyörä parkkiin',
    'bike-park-disclaimer-link': 'Lue lisää liityntäpyöräpysäköinnistä',
    'bike-station-disabled': 'Pyöräasema ei ole käytössä.',
    'bikes-available': 'Pyöriä asemalla {amount}',
    'biketransport-citybike': 'Käytän kaupunkipyörää',
    'biketransport-only-bike': 'Liikun vain pyörällä',
    'biking-amount': 'Pyöräilyn määrä',
    'biking-speed': 'Pyöräilynopeus',
    bus: 'Bussi',
    'bus-express': 'Runkolinjabussi',
    'bus-local': 'Lähibussi',
    'bus-with-route-number': 'Bussi {routeNumber} {headSign}',
    buy: 'Osta',
    'buy-ticket': 'Näin ostat lipun',
    call: 'Soita',
    cancel: 'Peruuta',
    canceled: 'Peruttu',
    'canceled-itineraries-amount':
      'Lisäksi {itineraryAmount, plural, =1 {1 peruttu reittiehdotus} other {{itineraryAmount} peruttua reittiehdotusta}}',
    'canceled-itineraries-amount-hide':
      'Piilota perutut reittiehdotukset ({itineraryAmount})',
    'canceled-legs': 'Reitillä peruttuja vuoroja',
    car: 'Auto',
    'car-distance-duration': 'Autoile {duration} ({distance})',
    'car-distance-no-duration': 'Autoile {distance}',
    'car-park-disclaimer':
      'Voit yhdistää kätevästi joukkoliikenteen ja autoilun, kun jätät autosi liityntäpysäköintiin ja jatkat matkaasi junalla, bussilla, metrolla tai ratikalla. Auton pysäköinti on useissa paikoissa maksutonta eikä siinä ole aikarajaa.',
    'car-park-disclaimer-header': 'Jätä auto parkkiin',
    'car-park-disclaimer-link': 'Lue lisää liityntäpysäköinnistä',
    car_park: 'Liityntäpysäköinti',
    cause: 'syy',
    'char-left': 'merkkiä',
    'choose-freely': 'Vapaasti valittavat',
    'choose-stop': 'Valitse pysäkki',
    'choose-stop-or-vehicle': 'Valitse linja tai pysäkki',
    'choose-vehicle': 'Valitse linja',
    citybike: 'Kaupunkipyörä',
    'citybike-distance-duration': 'Pyöräile {duration} ({distance})',
    'citybike-duration-general-header':
      'Useampi reitin kaupunkipyöräosuuksista sisältää lisämaksuja.',
    'citybike-duration-info':
      'Kaupunkipyörää voi käyttää yhtäjaksoisesti {duration} min, jonka jälkeen peritään lisämaksu.',
    'citybike-duration-info-header':
      'Reitin kaupunkipyöräosuus kestää yli {duration} min.',
    'citybike-duration-info-short':
      'Osuus yli {duration} min. Sisältää lisämaksun.',
    'citybike-network-headers': 'Kaupunkipyörät',
    'citybike-purchase-link': 'Siirry ostamaan',
    'citybike-register-required':
      'Kaupunkipyörien käyttö vaatii rekisteröitymisen',
    'citybike-return-full': 'Palauttamassa kaupunkipyörää?',
    'citybike-return-full-link':
      'Näin palautat pyörän, kun pyöräasema on täynnä ›',
    'citybike-start-using': 'Ota kaupunkipyörät käyttöön',
    'citybike-start-using-info': 'Lisätietoja',
    'citybike-station': 'Kaupunkipyöräasema {stationId}',
    'citybike-station-no-id': 'Kaupunkipyöräasema',
    'clear-button-label': 'Tyhjennä',
    close: 'Sulje',
    'close-favourite-module': 'Sulje suosikkimoduuli',
    'close-settings': 'Sulje ikkuna ja tallenna asetukset',
    'contact-information': '{contactName}:n yhteystiedot',
    continue: 'Jatka',
    'cookie-settings': 'Evästeasetukset',
    'copy-code': 'Kopioi koodi',
    covered: 'Katettu',
    'create-account': 'Luo {contactName} tunnus',
    'create-embedded-search': 'Luo reittihakuelementti',
    'create-stop-monitor': 'Luo pysäkkinäyttö',
    'cycle-distance-duration': 'Pyöräile {duration} ({distance})',
    'cyclewalk-distance-duration': 'Taluta pyörää {duration} ({distance})',
    // eslint-disable-next-line sort-keys
    currentposition: 'Tämänhetkinen sijainti',
    datasources: 'Tietolähteet',
    date: 'Päivämäärä',
    day: 'Päivä',
    delete: 'Poista',
    'delete-favourite-failed-heading': 'Tallennuksen poistaminen ei onnistunut',
    'departure-list-update.sr-instructions':
      'Tulevien lähtöjen lista ja lähtöajat päivittyvät reaaliajassa.',
    'departure-page-sr': 'Lähdön {shortName} {destination} {time} tiedot',
    'departure-time-in-minutes': '{minutes} min',
    'departure-time-sr': '{when} kello {time}. {realTime}',
    departures: 'Lähdöt',
    'departures-by-hour': 'Lähdöt tunneittain',
    'departures-by-hour-minutes-route': '(minuutit/linja)',
    'departures-not-found':
      'Valitsemallesi pysäkille ei valitettavasti löytynyt aikataulua',
    'departures-not-found-time-threshold':
      'Valitsemallesi pysäkille ei valitettavasti löytynyt aikataulua. Syynä voi olla se, että hakemasi päivämäärä on niin pitkällä tulevaisuudessa. Ole hyvä ja yritä uudelleen lähempänä matkasi ajankohtaa',
    description: 'Löydä joukkoliikennetarjonta lähelle ja kauas.',
    destination: 'Määränpää',
    'destination-defined': 'Määränpää määritetty',
    'destination-label-change': 'Vaihda määränpää',
    'destination-outside-service':
      'Reittiehdotuksia ei löytynyt, koska määränpää sijaitsee palvelualueen ulkopuolella.',
    'destination-placeholder': 'Kirjoita määränpää, linja tai pysäkki',
    'dialog-return-to-map': 'Takaisin karttaan',
    'digitransit-platform': 'Digitransit-palvelualusta',
    disclaimer:
      'Tulokset perustuvat arvioituihin ajoaikoihin. Ehdotetun yhteyden toteutumista ei voida taata.',
    disruption: 'Häiriö',
    'disruption-info': 'Poikkeusinfo',
    'disruption-info-no-alerts':
      'Ei tiedossa olevia häiriöitä tai poikkeuksia.',
    'disruption-info-route-no-alerts':
      'Linjalla ei tiedossa olevia häiriöitä tai poikkeuksia.',
    disruptions: 'Häiriöt',
    'disruptions-and-diversions': 'Häiriöt ja poikkeukset',
    'disruptions-tab.sr-disruptions': 'Yksi tai useampi häiriö tiedossa',
    'disruptions-tab.sr-no-disruptions': 'Ei tiedossa olevia häiriöitä',
    'distance-between': 'Etäisyys {distance1} m — {distance2} m',
    'distance-total': 'Matkan pituus',
    'distance-under': 'Etäisyys alle {distance} m',
    earlier: 'Aiempia lähtöjä',
    'edit-favourites': 'Muokkaa paikkaa Suosikkisi-välilehdellä',
    'elevation-gained-total': 'Nousua yhteensä',
    'elevation-lost-total': 'Laskua yhteensä',
    'embedded-search.choose-language': 'Näkymän kieli',
    'embedded-search.choose-mode': 'Liikkumistapa',
    'embedded-search.choose-width-component': 'Upotuksen leveys',
    'embedded-search.form-heading': 'Upotusvalinnat',
    engine_ignition_aid: 'Käynnistysapu',
    english: 'Englanti',
    explanations: 'Selitykset',
    'extra-info': 'Lisätiedot',
    'favourite-failed-text': 'Yritä hetken päästä uudelleen.',
    'favourite-target': 'Suosikkikohde',
    ferry: 'Lautta',
    'ferry-with-route-number': 'Lautta {routeNumber} {headSign}',
    'fetch-new-route': 'Hae uusi reitti',
    finnish: 'Suomi',
    'free-of-charge': 'Maksuton',
    'from-ferry': 'lautasta',
    'from-rail': 'junasta',
    'from-station': 'asemalta',
    'from-stop': 'pysäkiltä',
    'from-subway': 'metrosta',
    frontpage: 'Etusivu',
    funicular: 'Funikulaari',
    'generic-cancelation': '{mode} {route} {headsign} kello {time} on peruttu.',
    'generic-error': 'Tapahtui virhe',
    'geolocate-yourself': 'Paikanna',
    'geolocation-denied-heading': 'Paikannus on estetty',
    'geolocation-denied-text':
      'Et ole antanut lupaa paikannukseen. Voit sallia paikannuksen selaimen tai puhelimen asetuksista.',
    'geolocation-failed-heading': 'Paikannus epäonnistui',
    'geolocation-failed-text':
      'Paikannus ei toimi tai selaimesi ei tue paikannusta.',
    'geolocation-prompt-text': 'Hyväksy selaimen paikannuspyyntö.',
    'geolocation-timeout-heading': 'Paikannus kestää odotettua pidempään.',
    'geolocation-timeout-text': 'Oletko hyväksynyt selaimen paikannuspyynnön?',
    'give-destination': 'Määränpää',
    'give-name-to-location': 'Anna paikalle kuvaava nimi',
    'give-origin': 'Lähtöpaikka',
    'give-position': 'Kirjoita sijaintisi tai lähtöpaikkasi hakukenttään.',
    'give-route': 'Hae linjaa',
    hour: 'Tunti',
    'hour-short': 'h',
    'how-to-rate-service': 'Kuinka arvioisit palvelua?',
    hsl_mobile_ticket: 'HSL-mobiililipulla',
    hsl_ticket: 'HSL-lipulla',
    hsl_travel_card: 'HSL-kortilla',
    'in-addition': 'Lisäksi',
    'include-estonia': 'Sisällytä Viron liikenne',
    'index.title': 'Reittiopas',
    inquiry: 'Mitä pidät uudesta Reittioppaasta? Kerro se meille! ',
    instructions: 'Ohjeet',
    'is-open': 'Avoinna:',
    'itinerary-details.biking-leg':
      '{time} pyöräile kohteesta {origin} {distance} {to} {destination}. Matka-aika {duration}',
    'itinerary-details.car-leg':
      '{time} aja {distance} kohteesta {origin} {to} {destination}. Matka-aika {duration}',
    'itinerary-details.end-leg': '{time} saavu kohteeseen {destination}.',
    'itinerary-details.route-has-info-alert': 'Reitillä on tiedotteita.',
    'itinerary-details.route-has-severe-alert': 'Reitillä on poikkeuksia.',
    'itinerary-details.route-has-unknown-alert': 'Reitillä on häiriöitä.',
    'itinerary-details.route-has-warning-alert': 'Reitillä on häiriöitä.',
    'itinerary-details.scooter-leg':
      '{time} potkulautaile {distance} kohteesta {origin} kohteeseen {destination}. Matka-aika {duration}',
    'itinerary-details.transit-leg-part-1': '{time} {realtime} ota',
    'itinerary-details.transit-leg-part-2':
      'pysäkiltä {startStop} {startZoneInfo} {trackInfo} pysäkille {endStop} {endZoneInfo}. Arvioitu matka-aika {duration}',
    'itinerary-details.via-leg':
      '{arrivalTime} saavu välipisteeseen {viaPoint}. {leaveAction}',
    'itinerary-details.walk-leg':
      '{time} kävele kohteesta {origin} {to} {destination}. Matka-aika {duration}',
    'itinerary-feedback-button': 'Lähetä palautetta',
    'itinerary-feedback-message': 'Etkö löytänyt mitä etsit?',
    'itinerary-feedback-placeholder': 'Kuvaus (valinnainen)',
    'itinerary-hide-alternative-legs': 'Piilota vaihtoehdot',
    'itinerary-hide-stops': 'Piilota pysäkit',
    'itinerary-in-the-past': 'Reittihaun ajankohta on menneisyydessä.',
    'itinerary-in-the-past-link': 'Muuta lähtöajaksi nyt ›',
    'itinerary-in-the-past-title': 'Reittivaihtoehtoja ei voida näyttää',
    'itinerary-page.description': 'Reittiohje',
    'itinerary-page.hide-details': 'Piilota reittiohje',
    'itinerary-page.itineraries-loaded': 'Lisää hakutuloksia ladattu',
    'itinerary-page.itineraries-updated': 'Hakutulokset päivitetty',
    'itinerary-page.loading-itineraries': 'Ladataan lisää hakutuloksia',
    'itinerary-page.show-details': 'Näytä reittiohje',
    'itinerary-page.show-details-label': 'Näytä reittiohje {number}',
    'itinerary-page.title': 'Reittiohje',
    'itinerary-page.update-alert': 'Hakutulokset päivitetty',
    'itinerary-summary-page-street-mode.update-alert':
      'Kävely ja pyöräily hakutulokset päivitetty',
    'itinerary-summary-page.description': 'Reittiehdotukset',
    'itinerary-summary-row.biking-distance':
      'Pyöräilyä yhteensä {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Näytä kartalla',
    'itinerary-summary-row.description':
      'Lähtö {departureDate} kello {departureTime}. Perillä {arrivalDate} kello {arrivalTime}. {firstDeparture} {transfers} Matka-aika {totalTime}',
    'itinerary-summary-row.first-departure':
      '{vehicle} lähtee asemalta {stopName} kello {departureTime}.',
    'itinerary-summary-row.first-leg-start-time':
      'Lähtee klo {firstDepartureTime} {firstDepartureStopType} {firstDepartureStop}{firstDeparturePlatform}',
    'itinerary-summary-row.first-leg-start-time-citybike':
      'Lähtö klo {firstDepartureTime} kaupunkipyöräasemalta {firstDepartureStop}',
    'itinerary-summary-row.no-transit-legs': 'Lähde, kun sinulle sopii',
    'itinerary-summary-row.transfers': 'Vaihto {vehicle} pysäkillä {stopName}',
    'itinerary-summary-row.walking-distance':
      'Kävelyä yhteensä {totalDistance}.',
    'itinerary-summary.bikeAndPublic-fallback-title':
      'Ota pyöräsi mukaan kulkuneuvoon',
    'itinerary-summary.bikeAndPublic-rail-subway-title':
      'Ota pyöräsi mukaan junaan tai metroon',
    'itinerary-summary.bikeAndPublic-rail-title': 'Ota pyöräsi mukaan junaan',
    'itinerary-summary.bikeAndPublic-subway-title':
      'Ota pyöräsi mukaan metroon',
    'itinerary-summary.bikePark-title': 'Jätä pyöräsi liityntäpysäköintiin',
    'itinerary-summary.interline-wait':
      'Linjatunnus ({shortName}) ja määränpää ({destination}) vaihtuvat pysäkillä {stop}. Matka jatkuu {time} odotuksen jälkeen. Odota kulkuneuvossa.',
    'itinerary-summary.interline-wait-multiple-legs':
      'Linjatunnus ({shortName}) ja määränpää vaihtuvat useita kertoja matkan aikana. Odotusta yhteensä {time}. Odota ajoneuvossa.',
    'itinerary-summary.show-on-map': 'Näytä kartalla {target}',
    'itinerary-ticket.title': 'Tarvittava lippu',
    'itinerary-tickets.title': 'Reitillä tarvittavat liput',
    'itinerary-time.title': 'Matka-aika',
    'itinerary-walk.title': 'Kävelyä',
    'journeyplanner.title': 'HSL Reittiopas',
    'language-selection': 'Kieli: {language}',
    later: 'Myöhempiä lähtöjä',
    'leave-your-car-park-and-ride': 'Jätä autosi liityntäpysäköintiin',
    leaves: 'Lähtee',
    'leaving-at': 'Lähtöaika',
    lighting: 'Valaistus',
    'likely-to-recommend':
      'Kuinka todennäköistä on, että suosittelisit palveluamme ystävälle tai kollegallesi?',
    'likely-to-use':
      'Kuinka todennäköisesti käyttäisit tätä palvelua ennemmin kuin nykyistä reittiopas-palvelua?',
    loading: 'Ladataan...',
    localadmin: 'Paikkakunta',
    locate: 'Paikanna',
    'location-examples': 'esim. Koti, Työ, Koulu,...',
    'location-from-map': 'Valittu sijainti',
    'location-from-map-confirm': 'Vahvista valinta',
    'location-home': 'Koti',
    'location-school': 'Koulu',
    'location-shopping': 'Kauppa',
    'location-sport': 'Urheilu',
    'location-work': 'Työ',
    login: 'Siirry kirjautumaan',
    'login-content':
      'Paikkojen, linjojen ja pysäkkien tallentaminen ja hyödyntäminen edellyttää kirjautumista HSL-tunnuksella!',
    'login-header': 'Kirjaudu ensin',
    logout: 'Kirjaudu ulos',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Sulje päävalikko',
    'main-menu-label-open': 'Avaa päävalikko',
    'main-mode': 'Kulkumuoto',
    map: 'Kartta',
    'map-layer-citybike': 'Kaupunkipyöräasemat',
    'map-layer-park-and-ride': 'Liityntäpysäköintipaikat',
    'map-layer-stop-bus': 'Bussipysäkit',
    'map-layer-stop-ferry': 'Lautat',
    'map-layer-stop-funicular': 'Funikulaarit',
    'map-layer-stop-tram': 'Raitiovaunupysäkit',
    'map-layer-terminal-bus': 'Bussiterminaalit',
    'map-layer-terminal-rail': 'Juna-asemat',
    'map-layer-terminal-subway': 'Metroasemat',
    'map-layer-vehicles': 'Liikennevälineet',
    'menu-faq': 'UKK',
    'menu-feedback': 'Anna palautetta',
    'menu-link-to-privacy-policy': 'Rekisteriseloste',
    'messagebar-label-close-message-bar': 'Sulje banneri',
    'messagebar-label-page': 'Sivu',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Linja',
    'mobile-ticket-purchase-aria':
      'Mobiililipun osto. Osta {ticketName} lippu hintaan {price}. Osta lippu HSL sovelluksesta tai lataa sovellus',
    'modes.to-bike': 'kaupunkipyöräasemalle',
    'modes.to-bike-park': 'liityntäpyöräparkkiin',
    'modes.to-bus': 'bussipysäkille',
    'modes.to-car-park': 'liityntäpysäköintiin',
    'modes.to-place': 'kohteeseen',
    'modes.to-rail': 'juna-asemalle',
    'modes.to-stop': 'pysäkille',
    'modes.to-subway': 'metroasemalle',
    'modes.to-tram': 'raitiovaunupysäkille',
    'more-departures': 'Lisää lähtöjä',
    'more-settings': 'Lisäasetukset',
    'move-on-map': 'Siirry kartalla',
    'move-to-tab': 'Siirry välilehdelle {number}',
    navigate: 'Navigoi',
    nearest: 'Lähimmät {mode}',
    'nearest-favorites': 'Omat lähipysäkit',
    'nearest-favorites-browse-stops': 'Selaa ja valitse pysäkkejä',
    'nearest-favorites-no-favorites':
      'Valitsemiesi pysäkkien aikataulut ja ajoneuvot kartalla',
    'nearest-no-stops':
      'Valitun liikennevälineen asemia tai pysäkkejä ei ole lähialueella.',
    'nearest-stops': 'Lähimmät pysäkit',
    'nearest-stops-airplane': 'lentoasemat',
    'nearest-stops-bus': 'bussipysäkit',
    'nearest-stops-citybike': 'pyöräasemat',
    'nearest-stops-favorite': 'omat lähipysäkit',
    'nearest-stops-ferry': 'lauttalaiturit',
    'nearest-stops-rail': 'juna-asemat',
    'nearest-stops-subway': 'metroasemat',
    'nearest-stops-tram': 'raitiovaunupysäkit',
    'nearest-stops-update-location': 'Päivitä {mode}',
    'nearest-stops-updated-location': 'Kartan sijainti on muuttunut',
    'network-error': 'Verkkovirhe',
    // eslint-disable-next-line sort-keys
    neighbourhood: 'Naapurusto',
    next: 'Seuraava',
    'no-bike-allowed-popup-train':
      'Lähiliikenteen juniin pyörän voi ottaa mukaan ilmaiseksi, mutta pitkän matkan junissa peritään lisämaksu ja mahdollisesti vaaditaan etukäteisvaraus.',
    'no-bike-allowed-popup-tram-bus':
      'Pyörää ei voi ottaa bussiin tai raitiovaunuun. Jos käytät reitilläsi näitä kulkuvälineitä, jätä pyörä pysäkille tai liityntäpysäköintiin.',
    'no-departures': 'Ei lähteviä vuoroja',
    'no-position': 'Ei sijaintia',
    'no-position-no-stops':
      'Sijaintisi ei ole tiedossa, joten emme voi näyttää lähimpiä pysäkkejä.',
    'no-route-already-at-destination': 'Olet jo perillä.',
    'no-route-end': 'Syötä vielä määränpää',
    'no-route-end-date-not-in-range':
      'Aikataulu ei sisällä myöhäisempiä reittejä.',
    'no-route-found':
      'Linjaa {shortName} ({longName}) ei valitettavasti löytynyt.',
    'no-route-msg': 'Reittiehdotuksia ei valitettavasti löytynyt',
    'no-route-msg-time-threshold':
      'Reittiehdotuksia ei valitettavasti löytynyt. On mahdollista, ettei aikatauluja löydy vielä valitsemallesi päivälle. Ole hyvä ja yritä uudelleen lähempänä matkasi ajankohtaa',
    'no-route-msg-with-changes':
      'Reittiehdotuksia ei valitettavasti löytynyt. Ole hyvä ja tarkasta asettamasi hakuasetukset tai yritä muuttaa lähtöpaikkaa tai määränpäätä.',
    'no-route-origin-near-destination':
      'Reittiehdotuksia ei löytynyt, koska lähtöpaikka ja määränpää ovat niin lähellä toisiaan.',
    'no-route-origin-same-as-destination':
      'Reittiehdotuksia ei löytynyt, koska lähtöpaikka on sama kuin määränpää.',
    'no-route-showing-alternative-options':
      'Asettamillasi asetuksilla ei löytynyt yhtään reittiehdotusta. Löysimme kuitenkin seuraavia reittivaihtoehtoja:',
    'no-route-start': 'Syötä vielä lähtöpaikka',
    'no-route-start-date-too-early': 'Aikataulu ei sisällä aiempia reittejä.',
    'no-route-start-end': 'Syötä lähtöpaikka ja määränpää',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'Valitulle päivälle ({selectedDate}) ei löydy matkoja.',
    'notification-minimize': 'Sulje ilmoitus',
    'notification-open': 'Avaa ilmoitus',
    now: 'Nyt',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Ei pysähdyksiä} one {1 pysäkki} other {{number} pysäkkiä} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minuuttia} one {1 minuutti} other {{number} minuuttia}}',
    'number-of-spaces': 'Paikkojen määrä:',
    'off-canvas-info':
      'Kirjautumalla palveluun saat suosikit talteen ja voit hyödyntää niitä muillakin laitteillasi',
    'one-way-journey': 'Yhdensuuntaisen matkan pituus',
    'open-app': 'Avaa sovellus',
    'option-default': 'Keskimääräinen',
    'option-least': 'Hidas',
    'option-less': 'Rauhallinen',
    'option-more': 'Ripeä',
    'option-most': 'Nopea',
    or: 'tai',
    origin: 'Lähtöpaikka',
    'origin-and-destination': 'Lähtöpaikka ja määränpää',
    'origin-defined': 'Lähtöpaikka määritetty',
    'origin-label-change': 'Vaihda lähtöpaikka',
    'origin-outside-service':
      'Reittiehdotuksia ei löytynyt, koska lähtöpaikka sijaitsee palvelualueen ulkopuolella.',
    'origin-placeholder': 'Mistä? - osoite tai pysäkki',
    'other-dates': 'Muut ajankohdat',
    'own-position': 'Nykyinen sijaintisi',
    'page-not-found': 'Etsimääsi sivua ei löytynyt.',
    paid: 'Maksullinen',
    'park-and-ride': 'Liityntäpysäköinti',
    'park-and-ride-availability': 'Paikkoja vapaana:',
    'pay-attention': 'Huom!',
    payment_at_gate: 'Maksu portilla',
    'pick-icon': 'Valitse kuvake',
    'pick-mode': 'Liikennevälineet',
    'pick-mode-airplane-disabled': 'Lentokone ei valittu',
    'pick-mode-airplane-enabled': 'Lentokone valittu',
    'pick-mode-bus-disabled': 'Bussi ei valittu',
    'pick-mode-bus-enabled': 'Bussi valittu',
    'pick-mode-citybike-disabled': 'Kaupunkipyörä ei valittu',
    'pick-mode-citybike-enabled': 'Kaupunkipyörä valittu',
    'pick-mode-ferry-disabled': 'Lautta ei valittu',
    'pick-mode-ferry-enabled': 'Lautta valittu',
    'pick-mode-rail-disabled': 'Juna ei valittu',
    'pick-mode-rail-enabled': 'Juna valittu',
    'pick-mode-selected': 'Valitut joukkoliikennevälineet',
    'pick-mode-subway-disabled': 'Metro ei valittu',
    'pick-mode-subway-enabled': 'Metro valittu',
    'pick-mode-tram-disabled': 'Raitiovaunu ei valittu',
    'pick-mode-tram-enabled': 'Raitiovaunu valittu',
    'pick-street-mode': 'Omat kulkuvälineet',
    'place-route-or-keyword': 'Määränpää, linja tai pysäkki',
    platform: 'Laituri',
    'platform-num': 'Laituri {platformCode}',
    'platform-short': 'Lait. {platformCode}',
    'platform-short-no-num': 'Lait.',
    position: 'Sijainti',
    'prefer-walking': 'Suosi kävelyä',
    preview: 'Esikatselu',
    previous: 'Edelliset',
    print: 'Tulosta',
    'print-timetable': 'Viikkoaikataulu',
    'privacy-statement': 'Tietosuojaseloste',
    'public-transport': 'Joukkoliikenne',
    rail: 'Lähijuna',
    'rail-with-route-number': 'Juna {routeNumber} {headSign}',
    'read-more': 'Lue lisää',
    realtime: 'Reaaliaika',
    'realtime-matching': 'Realiajan integraatio',
    releases: 'Tiedotteet',
    'remove-favourite': 'Poista suosikkivalinta',
    'remove-via-button-label': 'Poista välipiste {index}',
    'rent-cycle-at': 'Nouda Kaupunkipyörä: ',
    'rent-scooter-at': 'Vuokraa potkulauta asemalta {station}',
    'required-ticket': 'Reitillä tarvittava lippu',
    'return-cycle-to': 'Palauta kaupunkipyörä:',
    'return-scooter-to': 'Palauta potkulauta asemalle {station}',
    'right-now': 'Juuri nyt',
    route: 'Linja',
    'route-add-viapoint': 'Välipiste',
    'route-custom-settings': 'Omat asetukset',
    'route-default': 'Perusasetukset',
    'route-destination-arrives': 'Vain poistuminen',
    'route-destination-endpoint': 'Saapuu / Päätepysäkki',
    'route-fastest': 'Nopea reitti',
    'route-from-here': 'Lähtöpaikka',
    'route-guide': 'Linjaohje',
    'route-here': 'Määränpää',
    'route-least-elevation-changes': 'Vältä korkeuseroja',
    'route-least-transfers': 'Vältä vaihtoja',
    'route-least-walking': 'Vältä kävelyä',
    'route-no-stop': 'Ei pysähdystä',
    'route-not-possible':
      'Valitettavasti reittisi ei ole mahdollinen. Tekninen virhe: "{error}"',
    'route-page-vehicle-position': '{mode} {shortName} on pysäkillä {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} on pysäkin {stopName} ja pysäkin {nextStopName} välissä.',
    'route-page-vehicle-selected': 'Tämänhetkinen valinta.',
    'route-page.description': 'Linja {shortName}, {longName}',
    'route-page.future-routes': 'Tulevat reitit',
    'route-page.pattern-chosen': 'Valittu',
    'route-page.pattern-select-title': 'Valitse tarkasteltava reitti',
    'route-page.similar-routes': 'Sinua saattaisi kiinnostaa myös',
    'route-page.special-routes': 'Muut reitit',
    'route-page.title': 'Linja {shortName}',
    'route-page.title-short': 'Linja',
    'route-to-ferry': 'Reitti laiturille',
    'route-to-park': 'Reitti parkille',
    'route-to-station': 'Reitti asemalle',
    'route-to-stop': 'Reitti pysäkille',
    'router-change-departure-time': 'Muuta lähtöaikaa ›',
    'router-location-not-found':
      'Ole hyvä ja valitse paikka tieverkolta tai pysäkiltä.',
    'router-location-not-found-title-1':
      'Määränpäähän ei ole määritelty kulkuyhteyttä',
    'router-location-not-found-title-2':
      'Lähtöpaikkaan ei ole määritelty kulkuyhteyttä',
    'router-location-not-found-title-3':
      'Lähtöpaikkaan eikä määränpäähän ole määritelty kulkuyhteyttä',
    'router-only-walk':
      'Haetulle reitille ei löydy kävelyä nopeampaa vaihtoehtoa.',
    'router-only-walk-title': 'Kulje reitti kävellen',
    'router-outside-bounds-3':
      'Reittiehdotuksia ei löytynyt, koska lähtöpaikka ja määränpää sijaistevat palvelun ulkopuolella.',
    'router-outside-service-period':
      'Valitsemaasi ajankohtaan ei ole saatavilla aikatalutietoja.',
    'router-stops-in-range-1':
      'Joukkoliikennepysäkkejä ei ole riittävän lähellä valittua määränpäätä.',
    'router-stops-in-range-2':
      'Joukkoliikennepysäkkejä ei ole riittävän lähellä valittua lähtöpaikkaa.',
    'router-stops-in-range-3':
      'Joukkoliikennepysäkkejä ei ole riittävän lähellä valittua lähtöpaikkaa eikä määränpäätä.',
    'router-system-error':
      'Järjestelmässä tapahtui virhe. Yritä myöhemmin uudelleen.',
    'router-transit-connection':
      'Ole hyvä ja yritä muuttaa lähtöpaikkaa tai määränpäätä.',
    'router-transit-connection-in-search-window':
      'Valittuna ajankohtana ei ole liikennöintiä.',
    'router-unable': 'Reittivaihtoehtoja ei voida näyttää',
    routes: 'Linjat',
    'routes-platforms': 'Linjat ja laiturit',
    'routes-tracks': 'Linjat ja raiteet',
    'routing-feedback-header': 'Oletko tyytyväinen reittiehdotuksiin?',
    'routing-feedback-link': 'Anna palautetta',
    run: 'Juoksu',
    save: 'Tallenna',
    'scooter-availability': 'Potkulautoja asemalla juuri nyt',
    'scooter-distance-duration': 'Potkulautaile {duration} ({distance})',
    'scooter-register-required':
      'Potkulautojen käyttö vaatii rekisteröitymisen',
    'scooterwalk-distance-duration':
      'Taluta potkulautaa {duration} ({distance})',
    search: 'Etsi',
    'search-button-label': 'Hae',
    'search-destination': 'Hae määränpäätä, linjaa tai pysäkkiä',
    'search-destination-index': 'Syötä määränpää',
    'search-destination-results-but-no-search':
      'Katso tulokset viereiseltä Määränpää-välilehdeltä',
    'search-fields.sr-instructions':
      'Reittihaku tehdään automaattisesti kun lähtöpaikka ja määränpää on asetettu. Haun parametrien muuttaminen laukaisee uuden reittihaun',
    'search-no-results': 'Ei tuloksia',
    'search-origin': 'Hae lähtöpaikkaa, linjaa tai pysäkkiä',
    'search-origin-index': 'Syötä lähtöpaikka',
    'search-position': 'Paikanna',
    'search-search-results-but-no-destination':
      'Katso tulokset viereiseltä "Tietoja linjasta tai pysäkistä" –välilehdeltä',
    'searching-position': 'Paikannetaan...',
    'select-all-routes': 'Valitse kaikki reitit',
    'select-map-layers-header': 'Näytä kartalla',
    'select-position': 'Valitse sijaintisi edellisistä hauistasi',
    'select-route': 'Valitse {mode}linja {shortName} kohti {headsign}',
    'selected-routes': 'Valitut linjat',
    send: 'Lähetä',
    'separate-ticket-required':
      'Tälle matkan osuudelle tarvitaan erillinen lippu.',
    'separate-ticket-required-disclaimer':
      'Matka sisältää yhden tai useamman osuuden, jonka lippujen myynnistä {agencyName} ei vastaa.',
    'set-specific-settings': 'Aseta tarkemmat asetukset',
    'set-stop-as-routes': 'Aseta pysäkki reitin',
    settings: 'Asetukset',
    'settings-alternative-name-rail': 'Juna',
    'settings-change-itinerary-settings': 'Muuta asetuksia',
    'settings-dropdown-close-label': 'Sulje vaihtoehdot',
    'settings-dropdown-open-label': 'Avaa vaihtoehdot',
    'settings-label-change': 'Muuta reittihaun asetuksia',
    'settings-missing-itineraries-body':
      'Asetuksissa on päällä valintoja, jotka rajaavat pois joitain reittivaihtoehtoja.',
    'settings-missing-itineraries-header': 'Puuttuuko reittivaihtoehtoja?',
    share: 'Jaa ohje',
    show: 'Näytä',
    'show-departures': 'Näytä lähdöt',
    'show-more': 'Näytä lisää',
    'show-more-stops-near-you': 'Näytä lisää pysäkkejä lähelläsi',
    'show-routes': 'Valitse linjat',
    'skip-positioning': 'Ohita',
    'skip-to-content': 'Siirry sisältöön',
    slow: 'Hidas',
    'specify-location': 'Määritä sijainti',
    'splash-locating': 'Paikannetaan',
    'splash-or-choose': 'tai valitse lähtöpaikaksi',
    'splash-use-positioning': 'Käytä paikannusta',
    'splash-welcome': 'Miten haluat aloittaa?',
    'splash-you-can-also': 'tai',
    station: 'Asema',
    stop: 'Pysäkki',
    'stop-departure-time-future': 'Lähtöaika {minutes} min päästä',
    'stop-departure-time-past': 'Lähtöaika oli',
    'stop-list-update.sr-instructions':
      'Pysäkkien lähtöajat päivittyvät reaaliajassa.',
    'stop-near-you': 'Linja, pysäkki tai asema',
    'stop-near-you-modal-grant-permission': 'tai salli paikannus',
    'stop-near-you-modal-grant-permission-info':
      'Voit sallia paikannuksen selaimen tai puhelimen asetuksista, jolloin palvelu pystyy hyödyntämään nykyistä sijaintiasi.',
    'stop-near-you-modal-header': 'Et ole sallinut paikannusta',
    'stop-near-you-modal-info':
      'Lähipysäkkien käyttö edellyttää tietoa sijainnista.',
    'stop-near-you-title': 'Linja- ja pysäkkihaku',
    'stop-near-you-update-alert': 'Lähimmät pysäkit päivitetty',
    'stop-number': 'Pysäkkinumero',
    'stop-page.description': 'Pysäkki - {name} {code}, {desc}',
    'stop-page.right-now.loaded': 'pysäkkisivu ladattu',
    'stop-page.title': 'Pysäkki - {name} {code}',
    'stop-page.title-short': 'Pysäkki',
    'stop-virtual-monitor': 'Pysäkkinäyttö',
    stops: 'Pysäkit',
    'stops-near-you.description':
      'Lähialueesi pysäkkiaikataulut ja ajoneuvot kartalla.',
    'stops-near-you.title': 'Lähipysäkkien aikataulut',
    'street-mode-bicycle': 'Pyöräily',
    'street-mode-bike-aria':
      'Näytä pyöräilyreitti. Kesto {duration}, pituus {length}',
    'street-mode-bikeandvehicle-aria':
      'Näytä pyöräilyn ja julkisen liikenteen yhdistelmä. Kesto {duration}, pyöräilyn pituus {length}',
    'street-mode-car': 'Auto',
    'street-mode-car-aria':
      'Näytä ajoreitti. Kesto {duration}, pituus {length}',
    'street-mode-car_park': 'Liityntä\u00ADpysäköinti',
    'street-mode-parkandride-aria':
      'Näytä liityntäpysäköintireitti. Kesto {duration}, pituus {length}',
    'street-mode-public_transport': 'Julkinen liikenne',
    'street-mode-walk': 'Kävely',
    'street-mode-walk-aria':
      'Näytä kävelyreitti. Kesto {duration}, pituus {length}',
    // eslint-disable-next-line sort-keys
    street: 'Katu',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Reittiehdotus {number}',
    'summary-page.title': 'Reittiehdotukset',
    surveillance_cameras: 'Kameravalvonta',
    'swap-order-button-label': 'Käännä reitin suunta vastakkaiseksi',
    swedish: 'Ruotsi',
    'swipe-disruption-info': 'Poikkeustietovälilehtien',
    'swipe-disruption-info-header': 'Poikkeustietovälilehdet.',
    'swipe-message-bar': 'Viestivälilehtien',
    'swipe-message-bar-header': 'Viestivälilehdet.',
    'swipe-result-tab-left':
      'Navigointipainike. Näytä edellinen välilehti painamalla enteriä tai välilyöntiä.',
    'swipe-result-tab-right':
      'Navigointipainike. Näytä seuraava välilehti painamalla enteriä tai välilyöntiä.',
    'swipe-result-tabs': 'Selaa välilehtiä nuolinäppäimillä.',
    'swipe-sr-new-tab-opened': 'Välilehti {number} avattu.',
    'swipe-stops-near-you': 'Lähipysäkkinäkymävälilehtien',
    'swipe-stops-near-you-header': 'Lähipysäkkinäkymävälilehdet.',
    'swipe-summary-page': 'Reittiehdotusvälilehtien',
    'swipe-summary-page-header': 'Reittiehdotusvälilehdet.',
    'terminal-page.description': 'Terminaali - {name}',
    'terminal-page.title': 'Terminaali - {name}',
    'terminal-page.title-short': 'Terminaali',
    'terms-of-use': 'Käyttöehdot',
    'ticket-and-price-info': 'Lippu- ja hintatiedot',
    'ticket-single-adult': 'Aikuinen',
    'ticket-type-none': 'Ei rajoitusta',
    tickets: 'Matkaliput',
    time: 'Aika',
    'time-selector-hours-label': 'Tunti',
    'time-selector-minutes-label': 'Minuutti',
    timetable: 'Aikataulu',
    'to-frontpage': 'Etusivulle',
    'to-rail': 'junaan',
    'to-subway': 'metroon',
    today: 'Tänään',
    tomorrow: 'Huomenna',
    track: 'Raide',
    'track-num': 'Raide {platformCode}',
    'track-short': 'Raide {platformCode}',
    'track-short-no-num': 'Raide',
    tram: 'Raitiovaunu',
    'tram-with-route-number': 'Raitiovaunu {routeNumber} {headSign}',
    transfer: 'Vaihto',
    transfers: 'Vaihtojen määrä',
    'transfers-allowed': 'Saa olla vaihtoja',
    'transfers-margin': 'Vaihtoaika vähintään',
    'travel-time': '{min} min',
    'travel-time-label': 'Matka-aika',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'Reitin CO2 päästöt',
    'trip-information': 'Lähdön tiedot',
    'trip-page.description': 'Linja {shortName}, {longName}',
    'trip-page.title': 'Linja {shortName}',
    'try-again': 'Yritä uudelleen',
    'use-citybike': 'Ota käyttöön',
    'use-national-service-postfix': '-palvelusta.',
    'use-national-service-prefix':
      'Suosittelemme kokeilemaan reittihakua koko maan kattavasta ',
    'use-own-position': 'Käytä nykyistä sijaintia',
    userinfo: 'Omat tiedot',
    usermenu: 'Käyttäjävalikko',
    'using-modes': 'Haluan kulkea',
    'very-likely': 'Erittäin todennäköistä',
    'very-unlikely': 'Erittäin epätodennäköistä',
    'via-leg-stop-duration': 'Välipisteessä {stayDuration}',
    'via-point': 'Välipiste',
    'via-point-index': 'Välipiste {index}',
    'viapoint-slack-amount': 'Pysähdyksen kesto',
    'view-route': 'Linjan tiedot',
    // eslint-disable-next-line sort-keys
    venue: 'Paikka',
    vr_ticket: 'VR-lipulla',
    wait: 'Odota',
    'wait-amount-of-time': 'Odota {duration}',
    walk: 'kävelyn',
    'walk-bike-itinerary-1':
      'Löysimme valitsemallesi reitille vain kävelyyn liittyviä reittiehdotuksia.',
    'walk-bike-itinerary-2':
      'Löysimme valitsemallesi reitille vain pyöräilyyn liittyviä reittiehdotuksia.',
    'walk-bike-itinerary-3':
      'Löysimme valitsemallesi reitille vain kävelyyn ja pyöräilyyn liittyviä reittiehdotuksia.',
    'walk-bike-itinerary-4':
      'Löysimme valitsemallesi reitille vain omiin kulkuneuvoihin liittyviä reittiehdotuksia.',
    'walk-distance-duration': 'Kävele {duration} ({distance})',
    'walk-only': 'Vain kävely',
    walking: 'Kävelyn määrä',
    'walking-speed': 'Kävelynopeus',
    'warning-call-agency': `Liikennöidään kutsujoukkoliikenteenä: {routeName},
      joka on tilattava etukäteen`,
    'warning-call-agency-no-route':
      'Liikennöidään kutsujoukkoliikenteenä, joka on tilattava etukäteen',
    weather: 'Sää',
    'weather-at-destination': 'Sää määränpäässä',
    'weather-data-source': 'Lähde: Ilmatieteen laitos',
    'weather-detail-title': 'Sääennuste reitin lähtöpaikassa klo',
    'weather-icon-1': 'Selkeää',
    'weather-icon-2': 'Puolipilvistä',
    'weather-icon-21': 'Heikkoja sadekuuroja',
    'weather-icon-22': 'Sadekuuroja',
    'weather-icon-23': 'Voimakkaita sadekuuroja',
    'weather-icon-3': 'Pilvistä',
    'weather-icon-31': 'Heikkoa vesisadetta',
    'weather-icon-32': 'Vesisadetta',
    'weather-icon-33': 'Voimakasta vesisadetta',
    'weather-icon-41': 'Heikkoja lumikuuroja',
    'weather-icon-42': 'Lumikuuroja',
    'weather-icon-43': 'Voimakkaita lumikuuroja',
    'weather-icon-51': 'Heikkoa lumisadetta',
    'weather-icon-52': 'Lumisadetta',
    'weather-icon-53': 'Voimakasta lumisadetta',
    'weather-icon-61': 'Ukkoskuuroja',
    'weather-icon-62': 'Voimakkaita ukkoskuuroja',
    'weather-icon-63': 'Ukkosta',
    'weather-icon-64': 'Voimakasta ukkosta',
    'weather-icon-71': 'Heikkoja räntäkuuroja',
    'weather-icon-72': 'Räntäkuuroja',
    'weather-icon-73': 'Voimakkaita räntäkuuroja',
    'weather-icon-81': 'Heikkoa räntäsadetta',
    'weather-icon-82': 'Räntäsadetta',
    'weather-icon-83': 'Voimakasta räntäsadetta',
    'weather-icon-91': 'Utua',
    'weather-icon-92': 'Sumua',
    where: 'Minne mennään?',
    yesterday: 'Eilen',
    zone: 'Vyöhyke',
    'zone-info': 'Vyöhyke {zone}',
    'zone-unknown': 'Tuntematon vyöhyke',
    zones: 'Lippuvyöhykkeet',
  },

  et: {
    'geolocation-denied-heading': 'Geolokatsioon on keelatud',
    'geolocation-denied-text':
      'Te pole andnud luba oma geograafilise asukoha kasutamiseks. Saate selle lubada telefoni või brauseri seadetes.',
    'geolocation-failed-heading': 'Geograafilise asukoha määramine ebaõnnestus',
    'geolocation-failed-text':
      'Teie brauser ei toeta asukoha otsimist.',
    'geolocation-prompt-text':
      'Nõustuge oma brauseri taotlusega juurdepääsuks Teie asukohale.',
    'geolocation-timeout-heading':
      'Teie asukoha tuvastamine võtab oodatust kauem aega.',
    'geolocation-timeout-text':
      'Kas olete nõustunud oma brauseri taotlusega juurdepääsuks teie asukohale?',
  },

  ru: {
    'geolocation-denied-heading': 'Геолокация запрещена',
    'geolocation-denied-text':
      'Вы не предоставили разрешение на использование вашего местоположения. Вы можете сделать это в настройках телефона или браузера.',
    'geolocation-failed-heading': 'Не удалось определить местоположение',
    'geolocation-failed-text':
      'Ваш браузер не поддерживает определение местоположения.',
    'geolocation-prompt-text':
      'Разрешите браузеру доступ к вашему местоположению.',
    'geolocation-timeout-heading':
      'Определение вашего местоположения занимает больше времени, чем ожидалось.',
    'geolocation-timeout-text':
      'Вы согласились предоставить браузеру доступ к вашему местоположению?',
  },
};

export default translations;
