/**
 * @generated SignedSource<<884e0b08efa83b152fe581304dc9604d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stopId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "stopId"
  }
],
v3 = {
  "kind": "Variable",
  "name": "startTime",
  "variableName": "startTime"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtfsId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "stopRoutes_StopAlertsContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "StopAlertsContainer_stop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "QueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "stopRoutes_StopAlertsContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Stop",
        "kind": "LinkedField",
        "name": "stop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Route",
            "kind": "LinkedField",
            "name": "routes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Alert",
            "kind": "LinkedField",
            "name": "alerts",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertDescriptionText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertHash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertHeaderText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertSeverityLevel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alertUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveEndDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectiveStartDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "stoptimes",
            "args": [
              {
                "kind": "Literal",
                "name": "numberOfDepartures",
                "value": 100
              },
              {
                "kind": "Literal",
                "name": "omitCanceled",
                "value": false
              },
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "timeRange",
                "value": 3600
              }
            ],
            "concreteType": "Stoptime",
            "kind": "LinkedField",
            "name": "stoptimesWithoutPatterns",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduledDeparture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "headsign",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "realtimeState",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Trip",
                "kind": "LinkedField",
                "name": "trip",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tripHeadsign",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Route",
                    "kind": "LinkedField",
                    "name": "route",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shortName",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f4e221afd279d03134e99fcef69ea6b",
    "id": null,
    "metadata": {},
    "name": "stopRoutes_StopAlertsContainer_Query",
    "operationKind": "query",
    "text": "query stopRoutes_StopAlertsContainer_Query(\n  $stopId: String!\n  $startTime: Long!\n) {\n  stop(id: $stopId) {\n    ...StopAlertsContainer_stop_2JfgHP\n    id\n  }\n}\n\nfragment StopAlertsContainer_stop_2JfgHP on Stop {\n  routes {\n    gtfsId\n    id\n  }\n  gtfsId\n  locationType\n  alerts {\n    id\n    alertDescriptionText\n    alertHash\n    alertHeaderText\n    alertSeverityLevel\n    alertUrl\n    effectiveEndDate\n    effectiveStartDate\n  }\n  stoptimes: stoptimesWithoutPatterns(startTime: $startTime, timeRange: 3600, numberOfDepartures: 100, omitCanceled: false) {\n    serviceDay\n    scheduledDeparture\n    headsign\n    realtimeState\n    trip {\n      tripHeadsign\n      route {\n        gtfsId\n        type\n        color\n        mode\n        shortName\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "70dbf43438eebcaf1682ffad4e5685b6";

module.exports = node;
